<template>
    <v-list>
      <template v-if="!allowMultiple">
        <v-list-item
          v-for="item in items"
          :key="item.id"
          :ripple="!approvedType(item!)"
          @click="selectItem(item)"
          :class="approvedType(item!) ? 'list-item-container' : 'list-item-button'"
        > 
        <event-coordinates-square-from v-if="item.type === 'coordinates-square'"
         @select="()=> $emit('select', item)"
        />

        <event-expenditure-balance v-else-if="item.type === 'expenditure-balance'"
         @select="()=> $emit('select', item)"
        />

        <event-text-area v-else-if="item.type === 'text-area'"
        :title="item.name"
        :last-item="item.children?.length === 0"
         @select="()=> $emit('select', item)"
        />

        <v-list-item-title v-else>{{ item.name }}</v-list-item-title>
        </v-list-item>
      </template>
      
      <template v-else>
        <v-list-item
          v-for="item in items"
          :key="item.id"
          class="list-item-button"
        >
          <v-list-item-title>
            <v-checkbox
              v-if="allowMultiple"
              :label="item.name"
              :value="item.name"
              v-model="selectedItems"
              @update:modelValue="emitSelection"
            />
            <v-radio
              v-else
              :label="item.name"
              :value="item.name"
              v-model="selectedItem"
              @update:modelValue="$emit('select', $event)"
            />
          </v-list-item-title>
        </v-list-item>
  
        <v-btn
          v-if="allowMultiple"
          :disabled="!selectedItems.length"
          class="custom-green-btn mt-4"
          @click="emitSelection"
        >
          Далі
        </v-btn>
      </template>
    </v-list>
  </template>
  
  <script setup lang="ts">
  import { ref, watch } from 'vue';
  import type { EventType } from '@/types/events';
  import EventTextArea from '@/components/EventCreation/EventTextArea.vue';
  import EventExpenditureBalance from '@/components/EventCreation/EventExpenditureBalance.vue';
  import EventCoordinatesSquareFrom from '@/components/EventCreation/EventCoordinatesSquareFrom.vue';

  
  const props = defineProps<{
    items: EventType[];
    selectionType: 'type' | 'subtype' | 'sub-subtype';
    allowMultiple: boolean;
    dialog: boolean;
  }>();
  
  const emit = defineEmits<{
    'select': [selection: EventType | string[] | string];
  }>();
  
  const selectedItems = ref<string[]>([]);
  const selectedItem = ref<string>('');

  function selectItem(item: EventType) {
    if(!approvedType(item)) {
      emit('select', item)
    }
  }

  
  function emitSelection() {
    if (props.allowMultiple) {
      emit('select', selectedItems.value);
    } else {
      emit('select', selectedItem.value);
    }
  }

  function approvedType(item: EventType) {
    return ['coordinates-square','expenditure-balance','text-area'].includes(item.type!)
  }
  
  // Reset selections when items change
  watch(() => props.items, () => {
    selectedItems.value = [];
    selectedItem.value = '';
  });
  </script>
  
  <style scoped>
  .list-item-button {
    background-color: #e0e0e0;
    margin-bottom: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }

  .list-item-container {
    background-color: #e0e0e0;
    margin-bottom: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 0px!important;
  }
  
  .list-item-button:hover {
    background-color: #d0d0d0;
  }
  
  .custom-green-btn {
    background-color: #4caf50;
    color: white;
    width: 100%;
  }
  </style>