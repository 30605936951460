import { createRouter, createWebHistory } from 'vue-router';
import DashboardView from '@/views/DashboardView.vue';
import PositionsView from '@/views/PositionsView.vue';
import StatsView from '@/views/StatsView.vue';
import AdminView from '@/views/AdminView.vue';
import Admin from '@/components/AdminView.vue'
import EventCreationDialog from '@/views/EventCreationDialog.vue';
import LoginView from '@/views/LoginView.vue';

const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/dashboard',
        component: DashboardView,
        children: [
            {
                path: '',
                redirect: { name: 'Event' }
            },
            {
                path: 'event',
                name: 'Event',
                component: PositionsView,
                children: [
                    {
                        path: 'event-type/:positionId',
                        name: 'EventCreationDialog',
                        component: EventCreationDialog
                    }
                ]
            },
            {
                path: 'stats',
                name: 'Stats',
                component: StatsView
            },
            {
                path: 'admin',
                name: 'Admin',
                component: AdminView
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach(async (to) => {
    const isAuthenticated = !!localStorage.getItem('user');

    if (
        // make sure the user is authenticated
        !isAuthenticated &&
        // ❗️ Avoid an infinite redirect
        to.name !== 'Login'
    ) {
        // redirect the user to the login page
        return { name: 'Login' }
    }
})

export default router;
