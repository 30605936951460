import { ref, reactive, watch } from 'vue';
import type { User, Squadron, Battalion, Position, ChatConfigState, ChatData } from '@/types/admin';

export function useAdminDialogs() {
  // Dialog visibility states
  const dialogStates = reactive({
    userDialog: false,
    squadronDialog: false,
    battalionDialog: false,
    positionDialog: false,
    chatConfigDialog: false
  });

  // Edit mode states
  const isEditMode = reactive({
    user: false,
    squadron: false,
    battalion: false,
    position: false
  });

  // Form states
  const userForm = reactive({
    id: null as string | null,
    username: '',
    password: '',
    role: 'user',
    callsign: '',
    squadronId: ''
  });

  const squadronForm = reactive({
    id: null as string | null,
    name: '',
    battalionId: null as string | null
  });

  const battalionForm = reactive({
    id: null as string | null,
    name: '',
    signalChats: [] as string[],
    whatsappChats: [] as string[],
    signalChatsData: [] as ChatData[],
    whatsappChatsData: [] as ChatData[]
  });

  const positionForm = reactive({
    id: null as string | null,
    name: '',
    description: '',
    squadronId: '',
    isGroup: false
  });

  // Chat states
  const selectedChat = ref<ChatConfigState | null>(null);
  const newSignalChat = ref('');
  const newWhatsappChat = ref('');

  // Reset functions
  function resetUserForm() {
    userForm.id = null;
    userForm.username = '';
    userForm.password = '';
    userForm.role = 'user';
    userForm.callsign = '';
    userForm.squadronId = '';
    isEditMode.user = false;
  }

  function resetSquadronForm() {
    squadronForm.id = null;
    squadronForm.name = '';
    squadronForm.battalionId = null;
    isEditMode.squadron = false;
  }

  function resetBattalionForm() {
    battalionForm.id = null;
    battalionForm.name = '';
    battalionForm.signalChats = [];
    battalionForm.whatsappChats = [];
    battalionForm.signalChatsData = [];
    battalionForm.whatsappChatsData = [];
    isEditMode.battalion = false;
    newSignalChat.value = '';
    newWhatsappChat.value = '';
  }

  function resetPositionForm() {
    positionForm.id = null;
    positionForm.name = '';
    positionForm.description = '';
    positionForm.squadronId = '';
    positionForm.isGroup = false;
    isEditMode.position = false;
  }

  // Open dialog functions with proper edit mode handling
  function openUserDialog(user?: User) {
    if (user) {
      // Edit mode
      isEditMode.user = true;
      userForm.id = user.id;
      userForm.username = user.username;
      userForm.role = user.role;
      userForm.callsign = user.callsign;
      userForm.squadronId = user.squadronId;
      userForm.password = ''; // Clear password in edit mode
    } else {
      // Create mode
      resetUserForm();
    }
    dialogStates.userDialog = true;
  }

  function openSquadronDialog(squadron?: Squadron) {
    if (squadron) {
      // Edit mode
      isEditMode.squadron = true;
      squadronForm.id = squadron.id;
      squadronForm.name = squadron.name;
      squadronForm.battalionId = squadron.battalionId;
    } else {
      // Create mode
      resetSquadronForm();
    }
    dialogStates.squadronDialog = true;
  }

  function openBattalionDialog(battalion?: Battalion) {
    if (battalion) {
      // Edit mode
      isEditMode.battalion = true;
      battalionForm.id = battalion.id;
      battalionForm.name = battalion.name;
      battalionForm.signalChats = battalion.signalChatsData?.map(chat => chat.name) || [];
      battalionForm.whatsappChats = battalion.whatsappChatsData?.map(chat => chat.name) || [];
      battalionForm.signalChatsData = battalion.signalChatsData || [];
      battalionForm.whatsappChatsData = battalion.whatsappChatsData || [];
    } else {
      // Create mode
      resetBattalionForm();
    }
    dialogStates.battalionDialog = true;
  }

  function openPositionDialog(position?: Position) {
    if (position) {
      // Edit mode
      isEditMode.position = true;
      Object.keys(position).forEach((key) => {
        //@ts-ignore
        positionForm[key] = position[key];
      });

    } else {
      // Create mode
      resetPositionForm();
    }
    dialogStates.positionDialog = true;
  }

  function openChatConfigDialog(chatType: 'signal' | 'whatsapp', battalion: Battalion, chatName: string) {
    const chatsData = chatType === 'signal' ? battalion.signalChatsData : battalion.whatsappChatsData;
    const chatData = chatsData?.find(chat => chat.name === chatName);
    
    if (chatData) {
      selectedChat.value = {
        name: chatName,
        type: chatType,
        battalion,
        config: chatData.config
      };
      dialogStates.chatConfigDialog = true;
    }
  }

  // Chat handling functions
  function addSignalChat() {
    if (newSignalChat.value.trim() && !battalionForm.signalChats.includes(newSignalChat.value.trim())) {
      battalionForm.signalChats.push(newSignalChat.value.trim());
      newSignalChat.value = '';
    }
  }

  function addWhatsappChat() {
    if (newWhatsappChat.value.trim() && !battalionForm.whatsappChats.includes(newWhatsappChat.value.trim())) {
      battalionForm.whatsappChats.push(newWhatsappChat.value.trim());
      newWhatsappChat.value = '';
    }
  }

  function removeChatFromArray(arrayName: 'signalChats' | 'whatsappChats', chat: string) {
    if (arrayName === 'signalChats') {
      battalionForm.signalChats = battalionForm.signalChats.filter(c => c !== chat);
    } else {
      battalionForm.whatsappChats = battalionForm.whatsappChats.filter(c => c !== chat);
    }
  }

  // Reset all dialogs
  function resetAllDialogs() {
    // Reset visibility
    dialogStates.userDialog = false;
    dialogStates.squadronDialog = false;
    dialogStates.battalionDialog = false;
    dialogStates.positionDialog = false;
    dialogStates.chatConfigDialog = false;
    
    // Reset forms
    resetUserForm();
    resetSquadronForm();
    resetBattalionForm();
    resetPositionForm();
    
    // Reset chat state
    selectedChat.value = null;
    newSignalChat.value = '';
    newWhatsappChat.value = '';
  }

  return {
    dialogStates,
    isEditMode,
    // Forms
    userForm,
    squadronForm,
    battalionForm,
    positionForm,
    // Chat states
    selectedChat,
    newSignalChat,
    newWhatsappChat,
    // Open functions
    openUserDialog,
    openSquadronDialog,
    openBattalionDialog,
    openPositionDialog,
    openChatConfigDialog,
    // Reset functions
    resetUserForm,
    resetSquadronForm,
    resetBattalionForm,
    resetPositionForm,
    resetAllDialogs,
    // Chat functions
    addSignalChat,
    addWhatsappChat,
    removeChatFromArray
  };
}
