import { ref, reactive } from 'vue';

export function useForm({ initialValues, validationRules }) {
  const form = reactive({ ...initialValues });
  const errors = reactive({});

  function validate() {
    let isValid = true;
    Object.keys(validationRules).forEach(field => {
      const rule = validationRules[field];
      const errorMessage = rule(form[field]);
      if (errorMessage !== true) {
        errors[field] = errorMessage;
        isValid = false;
      } else {
        delete errors[field];
      }
    });
    return isValid;
  }

  function resetForm() {
    Object.keys(form).forEach(key => {
      form[key] = initialValues[key];
    });
    Object.keys(errors).forEach(key => {
      delete errors[key];
    });
  }

  return {
    form,
    errors,
    validate,
    resetForm,
  };
}