<template>
    <v-dialog 
      :model-value="dialogVisible" 
      @update:model-value="$emit('update:dialogVisible', $event)"
      max-width="500px" 
      @click:outside="handleClose"
    >
      <v-card>
        <v-card-title>
          {{ isEditing ? 'Редагувати Пункт управління' : 'Створити Пункт управління' }}
        </v-card-title>
  
        <v-card-text>
          <v-alert
            v-if="alert.show"
            :type="alert.type"
            dismissible
            @input="alert.show = false"
            transition="scale-transition"
          >
            {{ alert.message }}
          </v-alert>
  
          <v-form ref="formRef" v-model="formValid">
            <v-text-field
              v-model="localForm.name"
              label="Назва"
              :rules="[v => !!v || 'Назва обовязкова']"
              required
            />
  
            <v-select
              v-model="localForm.battalionId"
              label="Підрозділ"
              :items="availableBattalions"
              item-title="name"
              item-value="id"
              :rules="[v => !!v || 'Підрозділ обовязковий']"
              required
            />
          </v-form>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer />
          <v-btn class="custom-red-btn" @click="handleClose">
            Відмінити
          </v-btn>
          <v-btn 
            class="custom-green-btn" 
            @click="handleSave"
            :disabled="!formValid"
          >
            Зберегти
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script setup lang="ts">
  import { ref, reactive, watch } from 'vue';
  import type { FormState } from '@/types/admin';
  
  interface Props {
    dialogVisible: boolean;
    isEditing: boolean;
    form: FormState['squadron'];
    availableBattalions: Array<{ id: string; name: string }>;
  }
  
  const props = defineProps<Props>();
  
  const emit = defineEmits<{
    (e: 'update:dialogVisible', value: boolean): void;
    (e: 'close'): void;
    (e: 'save', isEdit: boolean, formData: FormState['squadron']): void;
  }>();
  
  const formRef = ref<any>(null);
  const formValid = ref(false);
  
  const localForm = reactive(props.form);
  
  const alert = ref({
    show: false,
    type: 'error' as const,
    message: ''
  });
  
  function showAlert(message: string, type: 'error' | 'success' = 'error') {
    alert.value = {
      show: true,
      type,
      message
    };
  
    setTimeout(() => {
      alert.value.show = false;
    }, 3000);
  }
  
  async function handleSave() {
    const { valid } = await formRef.value?.validate();
    
    if (!valid) {
      showAlert('Будь ласка, заповніть всі обовязкові поля');
      return;
    }
  
    emit('save', props.isEditing, localForm);
  }
  
  function handleClose() {
    emit('update:dialogVisible', false);
    emit('close');
  }
  
  // Reset form validation when dialog opens
  watch(() => props.dialogVisible, (newVal) => {
    if (newVal) {
      formRef.value?.resetValidation();
    }
  });
  </script>
  
  <style scoped>
  .custom-green-btn {
    background-color: #4CAF50;
    color: white;
  }
  
  .custom-red-btn {
    background-color: #D32F2F;
    color: white;
  }
  </style>
  