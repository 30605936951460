import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

import { ref, watch } from 'vue';
  import type { EventType } from '@/types/events';
  import EventTextArea from '@/components/EventCreation/EventTextArea.vue';
  import EventExpenditureBalance from '@/components/EventCreation/EventExpenditureBalance.vue';
  import EventCoordinatesSquareFrom from '@/components/EventCreation/EventCoordinatesSquareFrom.vue';

  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'EventTypeSelector',
  props: {
    items: {},
    selectionType: {},
    allowMultiple: { type: Boolean },
    dialog: { type: Boolean }
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  
  const emit = __emit;
  
  const selectedItems = ref<string[]>([]);
  const selectedItem = ref<string>('');

  function selectItem(item: EventType) {
    if(!approvedType(item)) {
      emit('select', item)
    }
  }

  
  function emitSelection() {
    if (props.allowMultiple) {
      emit('select', selectedItems.value);
    } else {
      emit('select', selectedItem.value);
    }
  }

  function approvedType(item: EventType) {
    return ['coordinates-square','expenditure-balance','text-area'].includes(item.type!)
  }
  
  // Reset selections when items change
  watch(() => props.items, () => {
    selectedItems.value = [];
    selectedItem.value = '';
  });
  
return (_ctx: any,_cache: any) => {
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list = _resolveComponent("v-list")!

  return (_openBlock(), _createBlock(_component_v_list, null, {
    default: _withCtx(() => [
      (!_ctx.allowMultiple)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createBlock(_component_v_list_item, {
              key: item.id,
              ripple: !approvedType(item!),
              onClick: ($event: any) => (selectItem(item)),
              class: _normalizeClass(approvedType(item!) ? 'list-item-container' : 'list-item-button')
            }, {
              default: _withCtx(() => [
                (item.type === 'coordinates-square')
                  ? (_openBlock(), _createBlock(EventCoordinatesSquareFrom, {
                      key: 0,
                      onSelect: ()=> _ctx.$emit('select', item)
                    }, null, 8, ["onSelect"]))
                  : (item.type === 'expenditure-balance')
                    ? (_openBlock(), _createBlock(EventExpenditureBalance, {
                        key: 1,
                        onSelect: ()=> _ctx.$emit('select', item)
                      }, null, 8, ["onSelect"]))
                    : (item.type === 'text-area')
                      ? (_openBlock(), _createBlock(EventTextArea, {
                          key: 2,
                          title: item.name,
                          "last-item": item.children?.length === 0,
                          onSelect: ()=> _ctx.$emit('select', item)
                        }, null, 8, ["title", "last-item", "onSelect"]))
                      : (_openBlock(), _createBlock(_component_v_list_item_title, { key: 3 }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.name), 1)
                          ]),
                          _: 2
                        }, 1024))
              ]),
              _: 2
            }, 1032, ["ripple", "onClick", "class"]))
          }), 128))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
              return (_openBlock(), _createBlock(_component_v_list_item, {
                key: item.id,
                class: "list-item-button"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_list_item_title, null, {
                    default: _withCtx(() => [
                      (_ctx.allowMultiple)
                        ? (_openBlock(), _createBlock(_component_v_checkbox, {
                            key: 0,
                            label: item.name,
                            value: item.name,
                            modelValue: selectedItems.value,
                            "onUpdate:modelValue": [
                              _cache[0] || (_cache[0] = ($event: any) => ((selectedItems).value = $event)),
                              emitSelection
                            ]
                          }, null, 8, ["label", "value", "modelValue"]))
                        : (_openBlock(), _createBlock(_component_v_radio, {
                            key: 1,
                            label: item.name,
                            value: item.name,
                            modelValue: selectedItem.value,
                            "onUpdate:modelValue": [
                              _cache[1] || (_cache[1] = ($event: any) => ((selectedItem).value = $event)),
                              _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select', $event)))
                            ]
                          }, null, 8, ["label", "value", "modelValue"]))
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128)),
            (_ctx.allowMultiple)
              ? (_openBlock(), _createBlock(_component_v_btn, {
                  key: 0,
                  disabled: !selectedItems.value.length,
                  class: "custom-green-btn mt-4",
                  onClick: emitSelection
                }, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode(" Далі ")
                  ])),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true)
          ], 64))
    ]),
    _: 1
  }))
}
}

})