const expenditureAndBalance = {
  id: 287,
  name: "Витрати та залишок",
  displayText: null,
  disableLeftSpaces: true,
  type: "expenditure-balance",
  skipMapStep: true,
  children: [],
};

const coordinatesAndSquare = {
  id: 286,
  name: "Координати або квадрат",
  displayText: null,
  disableLeftSpaces: true,
  type: "coordinates-square",
  children: [expenditureAndBalance],
};

const result = {
  id: 272,
  name: "Результат",
  displayText: "Результат: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 273,
      name: "Вогневий вплив",
      displayText: "Вогневий вплив\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 274,
      name: "Влучання в ціль",
      displayText: "Влучання в ціль\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 275,
      name: "Влучання по координатах",
      displayText: "Влучання по координатах\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 276,
      name: "Подавлено",
      displayText: "Подавлено\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 277,
      name: "Знищено",
      displayText: "Знищено\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 278,
      name: "Пошкоджено",
      displayText: "Пошкоджено\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 279,
      name: "Заборона дії",
      displayText: "Заборона дії\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 280,
      name: "Репер створено",
      displayText: "Репер створено\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 281,
      name: "Задимлено",
      displayText: "Задимлено\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 282,
      name: "Заміновано",
      displayText: "Заміновано\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 283,
      name: "Результат невідомий",
      displayText: "Результат невідомий\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 284,
      name: "Результату немає",
      displayText: "Результату немає\n",
      disableLeftSpaces: true,
      children: [coordinatesAndSquare],
    },
    {
      id: 285,
      name: "Інше",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [coordinatesAndSquare],
    },
  ],
};

const target = {
  id: 232,
  name: "Ціль",
  displayText: "Ціль: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 233,
      name: "Піхота",
      displayText: null,
      disableLeftSpaces: true,
      children: [
        {
          id: 234,
          name: "Піхота",
          displayText: "Піхота\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 235,
          name: "Піхота у русі",
          displayText: "Піхота у русі\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 236,
          name: "Піхота в укритті",
          displayText: "Піхота в укритті\n",
          disableLeftSpaces: true,
          children: [result],
        },
      ],
    },
    {
      name: "Артилерійський засіб",
      displayText: null,
      disableLeftSpaces: true,
      id: 237,
      children: [
        {
          id: 238,
          name: "Артилерійський засіб",
          displayText: "Артилерійський засіб\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 239,
          name: "Міномет",
          displayText: "Міномет\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 240,
          name: "Міномет 82 мм",
          displayText: "Міномет, 82 мм\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 241,
          name: "Міномет 120 мм",
          displayText: "Міномет, 120 мм\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 242,
          name: "Гаубиця",
          displayText: "Гаубиця\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 243,
          name: "САУ",
          displayText: "САУ\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 244,
          name: "РСЗВ",
          displayText: "РСЗВ\n",
          disableLeftSpaces: true,
          children: [result],
        },
      ],
    },
    {
      name: "Техніка",
      displayText: null,
      disableLeftSpaces: true,
      id: 245,
      children: [
        {
          id: 246,
          name: "ББМ",
          displayText: "ББМ\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 247,
          name: "ВАТ",
          displayText: "ВАТ\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 248,
          name: "ЛАТ",
          displayText: "ЛАТ\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 249,
          name: "БМП",
          displayText: "БМП\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 250,
          name: "БТР",
          displayText: "БТР\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 251,
          name: "МТЛБ",
          displayText: "МТЛБ\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 252,
          name: "Танк",
          displayText: "Танк\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 253,
          name: "Мотоцикл",
          displayText: "Мотоцикл\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 254,
          name: "Квадроцикл",
          displayText: "Квадроцикл\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 255,
          name: "Баггі",
          displayText: "Баггі\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 256,
          name: "Інженерна техніка",
          displayText: "Інженерна техніка\n",
          disableLeftSpaces: true,
          children: [result],
        },
      ],
    },
    {
      name: "Склад",
      displayText: null,
      disableLeftSpaces: true,
      id: 257,
      children: [
        {
          id: 258,
          name: "Склад",
          displayText: "Склад\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 259,
          name: "Склад БК",
          displayText: "Склад БК\n",
          disableLeftSpaces: true,
          children: [result],
        },
        {
          id: 260,
          name: "Склад ПММ",
          displayText: "Склад ПММ\n",
          disableLeftSpaces: true,
          children: [result],
        },
      ],
    },
    {
      id: 261,
      name: "СПГ",
      displayText: "СПГ\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 262,
      name: "АГС",
      displayText: "АГС\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 263,
      name: "ПТРК",
      displayText: "ПТРК\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 264,
      name: "Розрахунок БПЛА",
      displayText: "Розрахунок БПЛА\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 265,
      name: "Бліндаж/укриття",
      displayText: "Бліндаж/укриття\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 266,
      name: "Засіб РЕБ",
      displayText: "Засіб РЕБ\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 267,
      name: "Антена",
      displayText: "Антена\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 268,
      name: "Створення реперу",
      displayText: "Створення реперу\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 269,
      name: "Задимлення",
      displayText: "Задимлення\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 270,
      name: "Мінування",
      displayText: "Мінування\n",
      disableLeftSpaces: true,
      children: [result],
    },
    {
      id: 271,
      name: "Інша ціль",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [result],
    },
  ],
};

const solution = {
  id: 201,
  name: "Засіб",
  displayText: "Засіб: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 202,
      name: "Міномет",
      displayText: "Міномет, ",
      disableLeftSpaces: true,
      children: [
        {
          id: 203,
          name: "60 мм",
          displayText: "60 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 204,
          name: "81 мм",
          displayText: "81 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 205,
          name: "82 мм",
          displayText: "82 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 206,
          name: "120 мм",
          displayText: "120 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
      ],
    },
    {
      id: 207,
      name: "Стрілецька зброя",
      displayText: null,
      disableLeftSpaces: true,
      children: [
        {
          id: 208,
          name: "Стрілецька зброя",
          displayText: "Стрілецька зброя\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 209,
          name: "Підствольний гранатомет",
          displayText: "Підствольний гранатомет\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 210,
          name: "Гранатомет",
          displayText: "Гранатомет\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 211,
          name: "Снайперська зброя",
          displayText: "Снайперська зброя\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 212,
          name: "Кулемет",
          displayText: "Кулемет\n",
          disableLeftSpaces: true,
          children: [target],
        },
      ],
    },
    {
      id: 213,
      name: "Великокаліберний кулемет",
      displayText: null,
      disableLeftSpaces: true,
      children: [
        {
          id: 214,
          name: "ДШК",
          displayText: "ДШК\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 215,
          name: "Browning M2",
          displayText: "Browning M2\n",
          disableLeftSpaces: true,
          children: [target],
        },
      ],
    },
    {
      id: 216,
      name: "ПТРК",
      displayText: null,
      disableLeftSpaces: true,
      children: [
        {
          id: 217,
          name: "Стугна",
          displayText: "Стугна\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 218,
          name: "Корнет",
          displayText: "Корнет\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 219,
          name: "FGM-48 Javelin",
          displayText: "FGM-48 Javelin\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 220,
          name: "MILAN",
          displayText: "MILAN\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 221,
          name: "TOW",
          displayText: "TOW\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 222,
          name: "NLAW",
          displayText: "NLAW\n",
          disableLeftSpaces: true,
          children: [target],
        },
      ],
    },
    {
      id: 223,
      name: "Гаубиця",
      displayText: "Гаубиця, ",
      disableLeftSpaces: true,
      children: [
        {
          id: 224,
          name: "105 мм",
          displayText: "105 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 225,
          name: "122 мм",
          displayText: "122 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 226,
          name: "152 мм",
          displayText: "152 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
        {
          id: 227,
          name: "155 мм",
          displayText: "155 мм\n",
          disableLeftSpaces: true,
          children: [target],
        },
      ],
    },
    {
      id: 228,
      name: "Mk-19",
      displayText: "Mk-19\n",
      disableLeftSpaces: true,
      children: [target],
    },
    {
      id: 229,
      name: "АГС",
      displayText: "АГС\n",
      disableLeftSpaces: true,
      children: [target],
    },
    {
      id: 230,
      name: "СПГ",
      displayText: "СПГ\n",
      disableLeftSpaces: true,
      children: [target],
    },
    {
      id: 231,
      name: "Інше",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [target],
    },
  ],
};

export default {
  id: 200,
  name: "Виконано вогневе завдання",
  displayText: "Виконано вогневе завдання\n\n",
  disableLeftSpaces: true,
  children: [solution],
};
