import fireMissionCompletedEvent from './fireMissionCompletedEvent';
import uavMissionCompletedEvent from './uavMissionCompletedEvent';
import combatReadiness from './combatReadiness';
import { EventType } from '@/types/events';

const eventConfig: { eventTypes:EventType[] } = 
    {
        "eventTypes": [
          {
            "name": "Почули",
            "id": 1,
            "children": [
              {
                "name": "Виходи",
                "displayText": "виходи",
                "id": 2,
                "children": [
                  {
                    "name": "Ствольна артилерія",
                    "displayText": "ствольної артилерії",
                    "id": 3
                  },
                  {
                    "name": "Міномет",
                    "displayText": "міномета,",
                    "id": 4,
                    "children": [
                      {
                        "name": "82мм",
                        "displayText": "82мм",
                        "id": 124
                      },
                      {
                        "name": "120мм",
                        "displayText": "120мм",
                        "id": 125
                      },
                      {
                        "name": "Великокаліберний міномет",
                        "displayText": "великокаліберного",
                        "id": 126
                      },
                      {
                        "name": "Не можливо визначити",
                        "displayText": "калібр якого не можливо визначити",
                        "id": 127
                      }
                    ]
                  },
                  {
                    "name": "Танк",
                    "displayText": "танка",
                    "id": 5
                  },
                  {
                    "name": "СПГ",
                    "id": 6
                  },
                  {
                    "name": "АГС",
                    "id": 7
                  },
                  {
                    "name": "Гармата 30мм",
                    "displayText": "гармати 30мм",
                    "id": 8
                  },
                  {
                    "name": "НАРи",
                    "displayText": "НАРів",
                    "id": 9
                  },
                  {
                    "name": "Реактивна артилерія",
                    "displayText": "реактивної артилерії",
                    "id": 10
                  },
                  {
                    "name": "Інше",
                    "displayText": "засобу іншого типу",
                    "id": 11
                  },
                  {
                    "name": "Не встановлено",
                    "displayText": "засобу не встановленого типу",
                    "id": 12
                  }
                ]
              },
              {
                "name": "Приходи",
                "displayText": "приходи",
                "id": 13,
                "children": [
                  {
                    "name": "Ствольна артилерія",
                    "displayText": "ствольної артилерії",
                    "id": 14
                  },
                  {
                    "name": "Міномет",
                    "displayText": "міномета",
                    "id": 15,
                    "children": [
                      {
                        "name": "82мм",
                        "displayText": "82мм",
                        "id": 128
                      },
                      {
                        "name": "120мм",
                        "displayText": "120мм",
                        "id": 129
                      },
                      {
                        "name": "Великокаліберний міномет",
                        "displayText": "великокаліберного",
                        "id": 130
                      },
                      {
                        "name": "Не можливо визначити",
                        "displayText": "калібр якого не можливо визначити",
                        "id": 131
                      }
                    ]
                  },
                  {
                    "name": "Танк",
                    "displayText": "танка",
                    "id": 16
                  },
                  {
                    "name": "СПГ",
                    "id": 17
                  },
                  {
                    "name": "АГС",
                    "id": 18
                  },
                  {
                    "name": "Гармата 30мм",
                    "displayText": "гармати 30мм",
                    "id": 19
                  },
                  {
                    "name": "НАРи",
                    "displayText": "НАРів",
                    "id": 20
                  },
                  {
                    "name": "КАБ",
                    "displayText": "КАБу",
                    "id": 21
                  },
                  {
                    "name": "Реактивна артилерія",
                    "displayText": "реактивної артилерії",
                    "id": 22
                  },
                  {
                    "name": "FPV",
                    "id": 23
                  },
                  {
                    "name": "Скид з дрона",
                    "displayText": "схожі на скид з дрона",
                    "id": 24
                  },
                  {
                    "name": "Інше",
                    "displayText": "засобу іншого типу",
                    "id": 25
                  },
                  {
                    "name": "Не встановлено",
                    "displayText": "засобу не встановленого типу",
                    "id": 26
                  }
                ]
              },
              {
                "name": "Техніка",
                "displayText": "техніку,",
                "id": 27,
                "children": [
                  {
                    "name": "Колісна",
                    "displayText": "колісну,",
                    "id": 28,
                    "children": [
                      {
                        "name": "Рухається",
                        "displayText": "що рухається",
                        "id": 29
                      },
                      {
                        "name": "Не рухається",
                        "displayText": "що не рухається",
                        "id": 30
                      }
                    ]
                  },
                  {
                    "name": "Гусенична",
                    "displayText": "гусеничну,",
                    "id": 31,
                    "children": [
                      {
                        "name": "Рухається",
                        "displayText": "що рухається",
                        "id": 32
                      },
                      {
                        "name": "Не рухається",
                        "displayText": "що не рухається",
                        "id": 33
                      }
                    ]
                  },
                  {
                    "name": "Не можливо визначити",
                    "displayText": "тип якої не можливо визначити,",
                    "id": 34,
                    "children": [
                      {
                        "name": "Рухається",
                        "displayText": "що рухається",
                        "id": 35
                      },
                      {
                        "name": "Не рухається",
                        "displayText": "що не рухається",
                        "id": 36
                      }
                    ]
                  }
                ]
              },
              {
                "name": "Стрілецький бій",
                "displayText": "стрілецький бій",
                "id": 122
              },
              {
                "name": "Стрілецька зброя",
                "displayText": "стрілецьку зброю",
                "id": 146
              },
              {
                "name": "Вибухи",
                "displayText": "вибухи",
                "id": 123
              },
              {
                "name": "Авіація",
                "displayText": "авіацію:",
                "id": 37,
                "children": [
                  {
                    "name": "Гелікоптер",
                    "displayText": "гелікоптер",
                    "id": 38
                  },
                  {
                    "name": "Літак",
                    "displayText": "літак",
                    "id": 39
                  },
                  {
                    "name": "Не встановлено",
                    "displayText": "тип не встановлено",
                    "id": 40
                  }
                ]
              },
              {
                "name": "БПЛА",
                "displayText": "БПЛА:",
                "id": 41,
                "children": [
                  {
                    "name": "Крило",
                    "displayText": "крило",
                    "id": 42
                  },
                  {
                    "name": "FPV",
                    "id": 43
                  },
                  {
                    "name": "Розвідувальний квадрокоптер",
                    "displayText": "розвідувальний квадрокоптер",
                    "id": 44
                  },
                  {
                    "name": "Важкий бомбер",
                    "displayText": "важкий бомбер",
                    "id": 45
                  }
                ]
              },
              {
                "name": "Інше",
                "displayText": "інше",
                "id": 46
              }
            ]
          },
          {
            "name": "Побачили",
            "id": 47,
            "children": [
              {
                "name": "Техніку противника",
                "displayText": "техніку противника:",
                "id": 48,
                "children": [
                  {
                    "name": "ЛАТ",
                    "id": 49
                  },
                  {
                    "name": "ВАТ",
                    "id": 50
                  },
                  {
                    "name": "БТР",
                    "id": 51
                  },
                  {
                    "name": "БМП",
                    "id": 52
                  },
                  {
                    "name": "Танк",
                    "displayText": "танк",
                    "id": 53
                  },
                  {
                    "name": "Не можливо визначити",
                    "displayText": "тип не можливо визначити",
                    "id": 54
                  }
                ]
              },
              {
                "name": "Піхота",
                "displayText": "піхоту",
                "id": 55
              },
              {
                "name": "Авіація",
                "displayText": "авіацію:",
                "id": 56,
                "children": [
                  {
                    "name": "Гелікоптер",
                    "displayText": "гелікоптер",
                    "id": 57
                  },
                  {
                    "name": "Літак",
                    "displayText": "літак",
                    "id": 58
                  },
                  {
                    "name": "Не встановлено",
                    "displayText": "тип не встановлено",
                    "id": 59
                  }
                ]
              },
              {
                "name": "Виходи",
                "displayText": "виходи",
                "id": 147,
                "children": [
                  {
                    "name": "Ствольна артилерія",
                    "displayText": "ствольної артилерії",
                    "id": 148
                  },
                  {
                    "name": "Міномет",
                    "displayText": "міномета,",
                    "id": 149,
                    "children": [
                      {
                        "name": "82мм",
                        "displayText": "82мм",
                        "id": 150
                      },
                      {
                        "name": "120мм",
                        "displayText": "120мм",
                        "id": 151
                      },
                      {
                        "name": "Великокаліберний міномет",
                        "displayText": "великокаліберного",
                        "id": 152
                      },
                      {
                        "name": "Не можливо визначити",
                        "displayText": "калібр якого не можливо визначити",
                        "id": 153
                      }
                    ]
                  },
                  {
                    "name": "Танк",
                    "displayText": "танка",
                    "id": 154
                  },
                  {
                    "name": "СПГ",
                    "id": 155
                  },
                  {
                    "name": "АГС",
                    "id": 156
                  },
                  {
                    "name": "Гармата 30мм",
                    "displayText": "гармати 30мм",
                    "id": 157
                  },
                  {
                    "name": "НАРи",
                    "displayText": "НАРів",
                    "id": 158
                  },
                  {
                    "name": "Реактивна артилерія",
                    "displayText": "реактивної артилерії",
                    "id": 159
                  },
                  {
                    "name": "Інше",
                    "displayText": "засобу іншого типу",
                    "id": 160
                  },
                  {
                    "name": "Не встановлено",
                    "displayText": "засобу не встановленого типу",
                    "id": 161
                  }
                ]
              },
              {
                "name": "Приходи",
                "displayText": "приходи",
                "id": 162,
                "children": [
                  {
                    "name": "Ствольна артилерія",
                    "displayText": "ствольної артилерії",
                    "id": 163
                  },
                  {
                    "name": "Міномет",
                    "displayText": "міномета",
                    "id": 164,
                    "children": [
                      {
                        "name": "82мм",
                        "displayText": "82мм",
                        "id": 165
                      },
                      {
                        "name": "120мм",
                        "displayText": "120мм",
                        "id": 166
                      },
                      {
                        "name": "Великокаліберний міномет",
                        "displayText": "великокаліберного",
                        "id": 167
                      },
                      {
                        "name": "Не можливо визначити",
                        "displayText": "калібр якого не можливо визначити",
                        "id": 168
                      }
                    ]
                  },
                  {
                    "name": "Танк",
                    "displayText": "танка",
                    "id": 169
                  },
                  {
                    "name": "СПГ",
                    "id": 170
                  },
                  {
                    "name": "АГС",
                    "id": 171
                  },
                  {
                    "name": "Гармата 30мм",
                    "displayText": "гармати 30мм",
                    "id": 172
                  },
                  {
                    "name": "НАРи",
                    "displayText": "НАРів",
                    "id": 173
                  },
                  {
                    "name": "КАБ",
                    "displayText": "КАБу",
                    "id": 174
                  },
                  {
                    "name": "Реактивна артилерія",
                    "displayText": "реактивної артилерії",
                    "id": 175
                  },
                  {
                    "name": "FPV",
                    "id": 176
                  },
                  {
                    "name": "Скид з дрона",
                    "displayText": "схожі на скид з дрона",
                    "id": 177
                  },
                  {
                    "name": "Інше",
                    "displayText": "засобу іншого типу",
                    "id": 178
                  },
                  {
                    "name": "Не встановлено",
                    "displayText": "засобу не встановленого типу",
                    "id": 179
                  }
                ]
              },
              {
                "name": "Вибухи",
                "displayText": "вибухи",
                "id": 60
              },
              {
                "name": "Спалах",
                "displayText": "спалах",
                "id": 61
              },
              {
                "name": "Дим",
                "displayText": "дим",
                "id": 62
              },
              {
                "name": "Трасуючі кулі",
                "displayText": "трасуючі кулі",
                "id": 132
              },
              {
                "name": "Сигнальна ракета",
                "displayText": "сигнальну ракету",
                "id": 63,
                "children": [
                  {
                    "name": "Біла",
                    "displayText": "білого кольору",
                    "id": 64
                  },
                  {
                    "name": "Жовта",
                    "displayText": "жовтого кольору",
                    "id": 65
                  },
                  {
                    "name": "Червона",
                    "displayText": "червоного кольору",
                    "id": 66
                  },
                  {
                    "name": "Інше",
                    "displayText": null,
                    "id": 67
                  }
                ]
              },
              {
                "name": "БПЛА",
                "displayText": "БПЛА:",
                "id": 68,
                "children": [
                  {
                    "name": "Крило",
                    "displayText": "крило",
                    "id": 69
                  },
                  {
                    "name": "FPV",
                    "id": 70
                  },
                  {
                    "name": "Розвідувальний квадрокоптер",
                    "displayText": "розвідувальний квадрокоптер",
                    "id": 71
                  },
                  {
                    "name": "Важкий бомбер",
                    "displayText": "важкий бомбер",
                    "id": 72
                  },
                  {
                    "name": "Ланцет",
                    "displayText": "ланцет",
                    "id": 73
                  },
                  {
                    "name": "Баражуючий боєприпас",
                    "displayText": "баражуючий боєприпас",
                    "id": 74
                  }
                ]
              },
              {
                "name": "Освітлювальна міна",
                "displayText": "освітлювальну міну",
                "id": 75
              },
              {
                "name": "Запалювальна міна",
                "displayText": "запалювальну міну",
                "id": 76
              },
              {
                "name": "Пожежа",
                "displayText": "пожежу",
                "id": 77
              },
              {
                "name": "Ракета",
                "displayText": "ракету",
                "id": 78
              },
              {
                "name": "Інше",
                "displayText": "інше",
                "id": 79
              }
            ]
          },
          {
            "name": "Обстріл",
            "id": 80,
            "isUnderFire": true,
            "children": [
              {
                "name": "Міномет",
                "displayText": "з міномета,",
                "id": 81,
                "children": [
                  {
                    "name": "82мм",
                    "displayText": "82мм",
                    "id": 82
                  },
                  {
                    "name": "120мм",
                    "displayText": "120мм",
                    "id": 83
                  },
                  {
                    "name": "Великокаліберний міномет",
                    "displayText": "великокаліберного",
                    "id": 84
                  },
                  {
                    "name": "Не можливо визначити",
                    "displayText": "калібр якого не можливо визначити",
                    "id": 85
                  }
                ]
              },
              {
                "name": "Ствольна артилерія",
                "displayText": "з ствольної артилерії",
                "id": 86
              },
              {
                "name": "Стрілецька зброя",
                "displayText": null,
                "id": 87,
                "children": [
                  {
                    "name": "Стрілецька зброя",
                    "displayText": "зі стрілецької зброї",
                    "id": 88
                  },
                  {
                    "name": "Великокаліберний кулемет",
                    "displayText": "з великокаліберного кулемета",
                    "id": 89
                  },
                  {
                    "name": "Підствольний гранатомет",
                    "displayText": "з підствольного гранатомета",
                    "id": 90
                  },
                  {
                    "name": "Гранатомет",
                    "displayText": "з гранатомета",
                    "id": 91
                  },
                  {
                    "name": "Снайперська зброя",
                    "displayText": "з снайперської зброї",
                    "id": 92
                  },
                  {
                    "name": "не можливо визначити",
                    "displayText": "зі стрілецької зброї, тип якої не можливо визначити",
                    "id": 93
                  }
                ]
              },
              {
                "name": "АГС",
                "displayText": "з АГС",
                "id": 94
              },
              {
                "name": "СПГ",
                "displayText": "з СПГ",
                "id": 95
              },
              {
                "name": "ПТРК",
                "displayText": "з ПТРК",
                "id": 96
              },
              {
                "name": "Танк",
                "displayText": "з танку",
                "id": 97
              },
              {
                "name": "РСЗВ",
                "displayText": "з РСЗВ",
                "id": 98
              },
              {
                "name": "Гармата 30мм",
                "displayText": "з гармати 30мм",
                "id": 99
              },
              {
                "name": "Гармата 100мм",
                "displayText": "з гармати 100мм",
                "id": 100
              },
              {
                "name": "Не можливо визначити",
                "displayText": "з засобу, тип якого не можливо визначити",
                "id": 101
              }
            ]
          },
          {
            "name": "Обстріл припинився",
            "id": 102,
            "skipMapStep": true,
            "isUnderFire": false
          },
          {
            "name": "Удар БПЛА",
            "displayText": null,
            "id": 103,
            "skipMapStep": true,
            "children": [
              {
                "name": "Удар FPV",
                "id": 104
              },
              {
                "name": "Скид з БПЛА",
                "id": 105
              },
              {
                "name": "Удар баражуючим боєприпасом",
                "id": 106
              },
              {
                "name": "Удар Ланцетом",
                "id": 107
              },
              {
                "name": "Скид горючої суміші з дрона",
                "id": 108
              }
            ]
          },
          {
            "name": "Авіаційний удар",
            "displayText": null,
            "id": 109,
            "skipMapStep": true,
            "children": [
              {
                "name": "Удар КАБом",
                "skipMapStep": true,
                "id": 110
              },
              {
                "name": "Удар НАРами",
                "skipMapStep": true,
                "id": 111
              }
            ]
          },
          {
            "name": "Застосування хімзброї",
            "displayText": null,
            "id": 112,
            "children": [
              {
                "name": "Скид з БПЛА (хімзброя)",
                "id": 113
              },
              {
                "name": "Удар FPV (хімзброя)",
                "id": 114
              },
              {
                "name": "Обстріл з міномета (хімзброя)",
                "id": 115
              },
              {
                "name": "Не можливо визначити",
                "displayText": "Застосування хімзброї",
                "id": 116
              }
            ]
          },
          {
            "name": "Штурмові дії ворога",
            "id": 117,
            "skipMapStep": true,
            "allies": true,
            "enemies": true,
            "isUnderAttack": true
          },
          {
            "name": "Штурмові дії ворога припинились",
            "id": 118,
            "skipMapStep": true,
            "isUnderAttack": false
          },
          {
            "name": "Підрив на мінуванні",
            "skipMapStep": true,
            "id": 119
          },
          {
            "name": "Пожежа",
            "id": 120,
            "skipMapStep": true
          },
          {
            "name": "Перевірка статусу",
            "skipMapStep": true,
            "id": 135
          },
          {
            "name": "300",
            "skipMapStep": true,
            "id": 136
          },
          {
            "name": "200",
            "skipMapStep": true,
            "id": 137
          },
          {
            "name": "Рух",
            "skipMapStep": true,
            "displayText": null,
            "id": 139,
            "children": [
              {
                "name": "Почали рух",
                "displayText": "Почали рух",
                "skipMapStep": true,
                "id": 140
              },
              {
                "name": "Зупинилися",
                "displayText": "Зупинилися",
                "skipMapStep": true,
                "id": 141
              },
              {
                "name": "Прибули",
                "displayText": "Прибули",
                "skipMapStep": true,
                "id": 142
              },
              {
                "name": "Провели ротацію",
                "displayText": "Провели ротацію",
                "skipMapStep": true,
                "id": 143
              },
              {
                "name": "Пройшли",
                "displayText": "Пройшли",
                "skipMapStep": true,
                "id": 144
              },
              {
                "name": "Готові до руху",
                "displayText": "Готові до руху",
                "skipMapStep": true,
                "id": 145
              }
            ]
          },
          {
            "name": "Втрата радіостанції",
            "skipMapStep": true,
            "id": 138
          },
          {
            "name": "Перехоплення ворожого радіоефіру",
            "skipMapStep": true,
            "id": 133
          },
          {
            "name": "Посадили ворожу FPV",
            "id": 134
          }
        ]
      }

      // Функція яка додає переноси рядків в тексті для подій які не мають дочірніх подій
      function processStructure(data: typeof eventConfig.eventTypes) {
        return data.map((node) => {
          const newNode = {
            ...node,
          };
          if (!node.children || node.children.length === 0) {
            if (node.displayText) {
              newNode.displayText = `${node.displayText}\n\n`;
            } else {
              newNode.name = `${node.name}\n\n`;
            }
          }
      
          if (node.children && node.children.length > 0) {
            newNode.children = processStructure(
              node.children as typeof eventConfig.eventTypes
            );
          }
      
          return newNode;
        });
      }
      
      const eventTypes = processStructure(eventConfig.eventTypes);
      
      eventConfig.eventTypes = eventTypes;
      
      eventTypes.push((fireMissionCompletedEvent as EventType))
      eventTypes.push((uavMissionCompletedEvent as EventType))
      eventTypes.push((combatReadiness as EventType))
      eventTypes.push({
        name: "Інше",
        displayText:'Інше\n\n',
        id: 121,
      })
      
export default eventConfig;