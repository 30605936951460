<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-text-field
        :label="title"
        v-model="textarea"
        variant="filled"
        single-line
        @keydown="onKeydown"
      ></v-text-field>
    </v-card-text>

    <v-card-actions class="d-flex justify-end mt-0">
      <v-btn class="custom-green-btn" @click="proceed" :disabled="disabled"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useEventCreationStore } from "@/stores/eventCreation";

const eventStore = useEventCreationStore();
const emit = defineEmits(["select"]);

const props = defineProps<{
  title: string;
  lastItem: boolean;
}>();

const textarea = ref("");
const disabled = computed(() => !textarea.value);

function onKeydown(e: KeyboardEvent) {
  e.stopPropagation();
}

function proceed() {
  eventStore.$patch({
    displayedDescription:
      (eventStore.displayedDescription += `${textarea.value}\n`),
  });

  emit("select");
}
</script>
