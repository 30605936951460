<!-- components/EventCreation/EventNotesForm.vue -->
<template>
  <div class="center-content">
    <v-textarea
      v-if="props.showAllies"
      v-model="notesData.allies"
      label="Кількість о/с на позиції"
      outlined
      rows="2"
      class="full-width"
    />

    <v-textarea
      v-if="props.showEnemies"
      v-model="notesData.enemies"
      label="Кільскість o/c противника"
      outlined
      rows="2"
      class="full-width"
    />

    <v-textarea
      v-model="notesData.details"
      label="Деталі"
      outlined
      rows="2"
      class="full-width"
    />

    <div class="status-section">
      <label>Статус</label>
      <v-radio-group
        :model-value="notesData.status"
        @update:model-value="handleStatusChange"
      >
        <v-radio
          v-for="status in statuses"
          :key="status"
          :label="status"
          :value="status"
        />
      </v-radio-group>
    </div>

    <div class="time-picker-container">
      <input
        type="time"
        id="time_picker"
        v-model="timeProxy"
        name="time"
        required
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from 'vue';
import type { EventNotes } from '@/types/events';
import { getCurrentTime } from '@/utils/timeUtils';

interface Props {
  modelValue: EventNotes;
  showAllies: boolean;
  showEnemies: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showAllies: false,
  showEnemies: false
});

const emit = defineEmits<{
  'update:modelValue': [value: EventNotes];
}>();

const statuses = [
  '4.5.0',
  '4.5.0, о/с цілий',
  '4.5.0, о/с та ОВТ цілі'
] as const;

const notesData = ref<EventNotes>({ ...props.modelValue });

const timeProxy = computed({
  get: () => notesData.value.time ? notesData.value.time : getCurrentTime(),
  set: (value: string) => notesData.value.time = value,
});

function handleStatusChange(status: string) {
  nextTick(() => {
    notesData.value = {
      ...notesData.value,
      status: status
    };
    emitUpdate();
  });
}

function emitUpdate() {
  emit('update:modelValue', { ...notesData.value });
}

// Watch for external changes
watch(() => props.modelValue, (newValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(notesData.value)) {
    notesData.value = { ...newValue };
  }
}, { deep: true });

// Watch for internal changes
watch(notesData, () => {
  emitUpdate();
}, { deep: true });
</script>

<style scoped>
.center-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.full-width {
  width: 100%;
}

.status-section {
  width: 100%;
  text-align: left;
}

.time-picker-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 16px 0;
}

#time_picker {
  font-size: 40px;
}

.v-radio-group {
  margin-top: 8px;
}

.v-radio {
  margin-bottom: 4px;
}

label {
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}
</style>