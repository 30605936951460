<template>
    <v-data-table-server
      :headers="headers"
      :items="eventsStore.formattedEvents"
      :items-length="eventsStore.eventsCount"
      :items-per-page="itemsPerPage"
      :loading="eventsStore.loading"
      :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }"
      class="elevation-1"
      item-value="id"
      @update:options="fetchEvents"
    >
      <template v-slot:[`item.notes`]="{ item }">
        {{ item.notes }}
      </template>
      <template v-slot:[`item.position`]="{ item }">
        {{ item.position }}
      </template>
      <template v-slot:[`item.time`]="{ item }">
        {{ new Date(item.time).toLocaleString() }}
      </template>
      <template v-slot:[`item.reporter`]="{ item }">
        {{ item.reporter.callsign }}
      </template>
      <template v-slot:[`item.mgrs`]="{ item }">
        <span class="mgrs-column-cell">{{ item.mgrs }}</span>
      </template>
    </v-data-table-server>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useEventsStore } from '@/stores/events';

  const eventsStore = useEventsStore();

  const headers = [
    { title: 'Тип події', key: 'type' },
    { title: 'Опис події', key: 'description' },
    { title: 'MGRS', key: 'mgrs' },
    { title: 'Додаткові нотатки', key: 'notes' },
    { title: 'Позиція', key: 'position' },
    { title: 'Час події', key: 'time' },
    { title: 'Позивний репортера', key: 'reporter.callsign' },
  ];

  const itemsPerPage = ref(50);

  const fetchEvents = ({ page, itemsPerPage }) => {
    eventsStore.fetchEvents(page, itemsPerPage);
  };

  </script>
  
  <style scoped>
  .mgrs-column-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  :deep(.v-data-table-header th) {
    font-weight: bold !important;
    background-color: #f5f5f5;
    text-align: center;
  }
  </style>
