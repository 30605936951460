import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, unref as _unref, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "distance-section"
}
const _hoisted_2 = { class: "input-section" }
const _hoisted_3 = { class: "checkbox-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "error-text"
}
const _hoisted_5 = { class: "checkbox-wrapper" }
const _hoisted_6 = {
  key: 1,
  class: "error-text"
}
const _hoisted_7 = {
  key: 1,
  class: "azimuth-section"
}
const _hoisted_8 = ["x2", "y2"]
const _hoisted_9 = ["x", "y"]
const _hoisted_10 = ["x2", "y2"]
const _hoisted_11 = ["points", "transform"]
const _hoisted_12 = { class: "map-container" }

import { ref, watch, nextTick, computed } from "vue";
import { LMap, LTileLayer, LMarker } from "vue3-leaflet";
import type { LatLng } from 'leaflet';
import "leaflet/dist/leaflet.css";
import DistanceSlider from "./DistanceSlider.vue";

interface Props {
  dialog: boolean;
}

const imageryUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
const imageryAttribution = "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
const labelsUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}";
const labelsAttribution = "Tiles &copy; Esri";

export default /*@__PURE__*/_defineComponent({
  __name: 'MapSelectionView',
  props: {
    dialog: { type: Boolean }
  },
  emits: ["update:dialog", "location-selected"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

// Refs
const activePanel = ref(0);
const zoom = ref(15);
const center = ref<[number, number]>([48.0159416, 37.7962839]);
const marker = ref<LatLng | null>(null);
const map = ref<any>(null);
const mapVisible = ref(false);

const distance = ref('0');
const azimuth = ref('0');
const azimuthError = ref(false);
const distanceError = ref(false);
const isDistanceUnknown = ref(false);
const isAzimuthUnknown = ref(false);
const proceedDisabledButton = ref(false);

// Map constants
const markerAngles = [0, 90, 180, 270];

// Map methods
const onMapReady = (mapInstance: any) => {
  map.value = mapInstance;
  loadLastLocation();
};

const loadLastLocation = () => {
  try {
    const lastLocationStr = localStorage.getItem("lastLocation");
    if (lastLocationStr) {
      const lastLocation = JSON.parse(lastLocationStr);
      center.value = [lastLocation.lat, lastLocation.lng];
      panToLocation(center.value);
    } else {
      getUserLocation();
    }
  } catch (error) {
    console.error('Error loading last location:', error);
    getUserLocation();
  }
};

const handleDistanceUnknownChange = () => {
  if (isDistanceUnknown.value) {
    distance.value = 'не можливо визначити';
    distanceError.value = false;
  } else {
    distance.value = '0';
  }
};

const handleAzimuthUnknownChange = () => {
  if (isAzimuthUnknown.value) {
    azimuth.value = 'не можливо визначити';
    azimuthError.value = false;
  } else {
    azimuth.value = '0';
  }
};

const getUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        center.value = [userLocation.lat, userLocation.lng];
        panToLocation(center.value);
      },
      () => {
        alert("Неможливо отримати ваші координати, оберіть точку вручну");
      }
    );
  }
};

const panToLocation = (location: [number, number]) => {
  if (map.value) {
    map.value.setView(location, zoom.value);
  }
};

const onMapClick = (event: { latlng: LatLng }) => {
  marker.value = event.latlng;
  center.value = [event.latlng.lat, event.latlng.lng];
  localStorage.setItem("lastLocation", JSON.stringify(marker.value));
};

// Validation methods
const validateAzimuth = () => {
  if (isAzimuthUnknown.value) {
    azimuthError.value = false;
    return;
  }

  const azimuthValue = azimuth.value.toString();
  // const singleAzimuthPattern = /^\d{1,3}$/;
  // const rangeAzimuthPattern = /^\d{1,3}-\d{1,3}$/;
  const singleAzimuthPattern = /^\s*\d{1,3}\s*$/
  const rangeAzimuthPattern = /^\s*\d{1,3}\s*-\s*\d{1,3}\s*$/;


  const azimuthParts = azimuthValue.split(',').map(part => part.trim());

  azimuthError.value = azimuthParts.some(part => {
    if (rangeAzimuthPattern.test(part)) {
      const [minAzimuth, maxAzimuth] = part.split('-').map(val => parseFloat(val.trim()));
      return isNaN(minAzimuth) || isNaN(maxAzimuth) ||
        minAzimuth < 0 || minAzimuth > 360 || maxAzimuth < 0 || maxAzimuth > 360;
    } else if (singleAzimuthPattern.test(part)) {
      const parsedAzimuth = parseFloat(part);
      return isNaN(parsedAzimuth) || parsedAzimuth < 0 || parsedAzimuth > 360;
    }
    return true;
  });
  azimuth.value = azimuthValue.replace(/\s/g, '');
};

const validateDistance = () => {
  if (isDistanceUnknown.value) {
    distanceError.value = false;
    return;
  }
  const parsedDistance = parseFloat(distance.value);
  distanceError.value = isNaN(parsedDistance) || parsedDistance < 0 || !/^\d+$/.test(distance.value);
};

const onAzimuthChange = () => {
  validateAzimuth();
};

const onDistanceChange = () => {
  validateDistance();
};

const showAzimuthArrow = computed(() => {
  return !isAzimuthUnknown.value && !isNaN(Number(azimuth.value));
});

// Azimuth circle methods
const setAzimuth = (event: MouseEvent) => {
  if (isAzimuthUnknown.value) return;

  const target = event.target as SVGElement;
  const circleRect = target.getBoundingClientRect();
  const centerX = circleRect.left + circleRect.width / 2;
  const centerY = circleRect.top + circleRect.height / 2;

  const deltaX = event.clientX - centerX;
  const deltaY = event.clientY - centerY;

  const radians = Math.atan2(deltaY, deltaX);
  let degrees = radians * (180 / Math.PI);

  degrees = (degrees + 90 + 360) % 360;
  azimuth.value = Math.round(degrees).toString();
  validateAzimuth();
};

const getArrowHeadPoints = () => {
  if (isAzimuthUnknown.value || isNaN(Number(azimuth.value))) {
    return '0,0 0,0 0,0'; // Return invisible points
  }

  const length = 80;
  const arrowSize = 10;
  const angle = parseFloat(azimuth.value) - 90;
  const x = 100 + length * Math.cos((angle * Math.PI) / 180);
  const y = 100 + length * Math.sin((angle * Math.PI) / 180);

  const leftX = x - arrowSize * Math.cos(((angle + 150) * Math.PI) / 180);
  const leftY = y - arrowSize * Math.sin(((angle + 150) * Math.PI) / 180);
  const rightX = x - arrowSize * Math.cos(((angle - 150) * Math.PI) / 180);
  const rightY = y - arrowSize * Math.sin(((angle - 150) * Math.PI) / 180);

  return `${x},${y} ${leftX},${leftY} ${rightX},${rightY}`;
};

// Dialog methods
const handleDialogUpdate = (value: boolean) => {
  if (!value) {
    resetState();
  }
  emit('update:dialog', value);
};

const resetState = () => {
  distance.value = '0';
  azimuth.value = '0';
  marker.value = null;
  mapVisible.value = false;
  activePanel.value = 0;
  distanceError.value = false;
  azimuthError.value = false;
  isDistanceUnknown.value = false;
  isAzimuthUnknown.value = false;
};

const proceed = () => {
  const selectedData = {
    distance: isDistanceUnknown.value ? 'не можливо визначити' : parseInt(distance.value),
    azimuth: isAzimuthUnknown.value ? 'не можливо визначити' : azimuth.value.includes('-') ? azimuth.value : parseInt(azimuth.value),
    coordinates: marker.value || { lat: 0, lng: 0 },
  };

  console.log('Emitting location data:', selectedData);

  emit('location-selected', selectedData);
  // handleDialogUpdate(false);
};

const skip = () => {
  const skippedData = {
    distance: -1,
    azimuth: -1,
    coordinates: { lat: 0, lng: 0 },
  };
  emit('location-selected', skippedData);
};

// Watchers
watch(() => props.dialog, (newValue) => {
  if (!newValue) {
    resetState();
  }
});

watch(activePanel, async (newVal) => {
  if (newVal === 1) {
    mapVisible.value = true;
    await nextTick();
    setTimeout(() => {
      if (map.value) {
        map.value.invalidateSize();
      }
    }, 500);
  } else {
    mapVisible.value = false;
  }
});

watch([azimuthError, distanceError], (value) => {
  if (value[0]||value[1]) {
    proceedDisabledButton.value = true;
  } else {
    proceedDisabledButton.value = false;
  }
});

watch(distance, () => {
  distanceError.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialog,
    "onUpdate:modelValue": handleDialogUpdate,
    "max-width": "800"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createElementVNode("span", { class: "headline" }, "Визначте координати", -1)
            ])),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panels, {
                modelValue: activePanel.value,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((activePanel).value = $event)),
                accordion: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { class: "header" }, {
                        default: _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("span", { class: "header-text" }, "Азимут та дистанція події", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          (!isDistanceUnknown.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                                _createElementVNode("div", null, "Дистанція: " + _toDisplayString(distance.value) + " m", 1),
                                _createVNode(DistanceSlider, {
                                  modelValue: distance.value,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((distance).value = $event)),
                                  disabled: isDistanceUnknown.value,
                                  "tick-marks": [
                  { value: 0, label: '0' },
                  { value: 2500, label: '2500' },
                  { value: 5000, label: '>5000' },
                ]
                                }, null, 8, ["modelValue", "disabled"])
                              ]))
                            : _createCommentVNode("", true),
                          _createElementVNode("div", _hoisted_2, [
                            _createVNode(_component_v_text_field, {
                              label: "Дистанція",
                              modelValue: distance.value,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((distance).value = $event)),
                              type: "text",
                              class: "distance-input",
                              "hide-details": "",
                              onInput: onDistanceChange,
                              disabled: isDistanceUnknown.value
                            }, null, 8, ["modelValue", "disabled"]),
                            _createElementVNode("div", _hoisted_3, [
                              _createVNode(_component_v_checkbox, {
                                modelValue: isDistanceUnknown.value,
                                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((isDistanceUnknown).value = $event)),
                                label: "Не можливо визначити",
                                "hide-details": "",
                                onChange: handleDistanceUnknownChange
                              }, null, 8, ["modelValue"])
                            ]),
                            (distanceError.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Дистанція некоректна"))
                              : _createCommentVNode("", true),
                            _cache[8] || (_cache[8] = _createElementVNode("div", { class: "helper-text" }, " Формат азимуту: кілька значень через кому, діапазон через тире, наприклад 70-75, 250, 40-50 ", -1)),
                            _createVNode(_component_v_text_field, {
                              label: "Азимут",
                              modelValue: azimuth.value,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((azimuth).value = $event)),
                              type: "text",
                              class: "azimuth-input",
                              "hide-details": "",
                              onInput: onAzimuthChange,
                              disabled: isAzimuthUnknown.value
                            }, null, 8, ["modelValue", "disabled"]),
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_v_checkbox, {
                                modelValue: isAzimuthUnknown.value,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isAzimuthUnknown).value = $event)),
                                label: "Не можливо визначити",
                                "hide-details": "",
                                onChange: handleAzimuthUnknownChange
                              }, null, 8, ["modelValue"])
                            ]),
                            (azimuthError.value)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Азимут некоректний"))
                              : _createCommentVNode("", true)
                          ]),
                          (!isAzimuthUnknown.value)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                (_openBlock(), _createElementBlock("svg", {
                                  class: "azimuth-circle",
                                  viewBox: "0 0 200 200",
                                  onClick: setAzimuth
                                }, [
                                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(markerAngles, (angle) => {
                                    return _createElementVNode("line", {
                                      key: angle,
                                      x1: 100,
                                      y1: 100,
                                      x2: 100 + 80 * Math.cos((angle - 90) * Math.PI / 180),
                                      y2: 100 + 80 * Math.sin((angle - 90) * Math.PI / 180),
                                      stroke: "black",
                                      "stroke-dasharray": "4"
                                    }, null, 8, _hoisted_8)
                                  }), 64)),
                                  (_openBlock(), _createElementBlock(_Fragment, null, _renderList(markerAngles, (angle) => {
                                    return _createElementVNode("text", {
                                      key: 'text' + angle,
                                      x: 100 + 90 * Math.cos((angle - 90) * Math.PI / 180),
                                      y: 100 + 90 * Math.sin((angle - 90) * Math.PI / 180),
                                      "font-size": "12",
                                      "text-anchor": "middle",
                                      "dominant-baseline": "middle"
                                    }, _toDisplayString(angle), 9, _hoisted_9)
                                  }), 64)),
                                  (showAzimuthArrow.value)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("line", {
                                          x1: 100,
                                          y1: 100,
                                          x2: 100 + 80 * Math.cos((Number(azimuth.value) - 90) * Math.PI / 180),
                                          y2: 100 + 80 * Math.sin((Number(azimuth.value) - 90) * Math.PI / 180),
                                          stroke: "black",
                                          "stroke-width": "2"
                                        }, null, 8, _hoisted_10),
                                        _createElementVNode("polygon", {
                                          points: getArrowHeadPoints(),
                                          fill: "black",
                                          transform: 'rotate(180 ' + (100 + 80 * Math.cos((Number(azimuth.value) - 90) * Math.PI / 180)) + ' ' + (100 + 80 * Math.sin((Number(azimuth.value) - 90) * Math.PI / 180)) + ')'
                                        }, null, 8, _hoisted_11)
                                      ], 64))
                                    : _createCommentVNode("", true)
                                ]))
                              ]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { class: "header" }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createElementVNode("span", { class: "header-text" }, "Вибрати на мапі", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_12, [
                            (mapVisible.value)
                              ? (_openBlock(), _createBlock(_unref(LMap), {
                                  key: 0,
                                  zoom: zoom.value,
                                  center: center.value,
                                  ref_key: "map",
                                  ref: map,
                                  onReady: onMapReady,
                                  onClick: onMapClick,
                                  style: {"height":"500px","width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(LTileLayer), {
                                      url: imageryUrl,
                                      attribution: imageryAttribution
                                    }),
                                    _createVNode(_unref(LTileLayer), {
                                      url: labelsUrl,
                                      attribution: labelsAttribution
                                    }),
                                    (marker.value)
                                      ? (_openBlock(), _createBlock(_unref(LMarker), {
                                          key: 0,
                                          "lat-lng": marker.value
                                        }, null, 8, ["lat-lng"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["zoom", "center"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "custom-default-btn",
                onClick: skip
              }, {
                default: _withCtx(() => _cache[10] || (_cache[10] = [
                  _createTextVNode("Пропустити")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn",
                onClick: proceed,
                disabled: proceedDisabledButton.value
              }, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("Далі")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})