import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "center-content" }
const _hoisted_2 = { class: "status-section" }
const _hoisted_3 = { class: "time-picker-container" }

import { ref, watch, computed, nextTick } from 'vue';
import type { EventNotes } from '@/types/events';
import { getCurrentTime } from '@/utils/timeUtils';

interface Props {
  modelValue: EventNotes;
  showAllies: boolean;
  showEnemies: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventNotesForm',
  props: {
    modelValue: {},
    showAllies: { type: Boolean, default: false },
    showEnemies: { type: Boolean, default: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const statuses = [
  '4.5.0',
  '4.5.0, о/с цілий',
  '4.5.0, о/с та ОВТ цілі'
] as const;

const notesData = ref<EventNotes>({ ...props.modelValue });

const timeProxy = computed({
  get: () => notesData.value.time ? notesData.value.time : getCurrentTime(),
  set: (value: string) => notesData.value.time = value,
});

function handleStatusChange(status: string) {
  nextTick(() => {
    notesData.value = {
      ...notesData.value,
      status: status
    };
    emitUpdate();
  });
}

function emitUpdate() {
  emit('update:modelValue', { ...notesData.value });
}

// Watch for external changes
watch(() => props.modelValue, (newValue) => {
  if (JSON.stringify(newValue) !== JSON.stringify(notesData.value)) {
    notesData.value = { ...newValue };
  }
}, { deep: true });

// Watch for internal changes
watch(notesData, () => {
  emitUpdate();
}, { deep: true });

return (_ctx: any,_cache: any) => {
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.showAllies)
      ? (_openBlock(), _createBlock(_component_v_textarea, {
          key: 0,
          modelValue: notesData.value.allies,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((notesData.value.allies) = $event)),
          label: "Кількість о/с на позиції",
          outlined: "",
          rows: "2",
          class: "full-width"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (props.showEnemies)
      ? (_openBlock(), _createBlock(_component_v_textarea, {
          key: 1,
          modelValue: notesData.value.enemies,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((notesData.value.enemies) = $event)),
          label: "Кільскість o/c противника",
          outlined: "",
          rows: "2",
          class: "full-width"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_textarea, {
      modelValue: notesData.value.details,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((notesData.value.details) = $event)),
      label: "Деталі",
      outlined: "",
      rows: "2",
      class: "full-width"
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("label", null, "Статус", -1)),
      _createVNode(_component_v_radio_group, {
        "model-value": notesData.value.status,
        "onUpdate:modelValue": handleStatusChange
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(statuses, (status) => {
            return _createVNode(_component_v_radio, {
              key: status,
              label: status,
              value: status
            }, null, 8, ["label", "value"])
          }), 64))
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "time",
        id: "time_picker",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((timeProxy).value = $event)),
        name: "time",
        required: ""
      }, null, 512), [
        [_vModelText, timeProxy.value]
      ])
    ])
  ]))
}
}

})