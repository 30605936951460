export default {
  id: 407,
  name: "Бойова готовність",
  displayText: "Бойова готовність\n\n",
  disableLeftSpaces: true,
  children: [
    {
      id: 408,
      name: "БГ",
      displayText: "В бойовій готовності\n",
      disableLeftSpaces: true,
      skipMapStep: true,
      children: [],
    },
    {
      id: 409,
      name: "Не БГ",
      displayText: "Не БГ\nПричина: ",
      disableLeftSpaces: true,
      children: [
        { 
          id: 410,
          name: "Причина",
          displayText: null,
          disableLeftSpaces: true,
          type: "text-area",
          skipMapStep: true,
          children: [],
        },
      ],
    },
  ],
};
