export function getCurrentTime(): string {
    const now = new Date();
    return `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}`;
}
  
export function formatDate(): string {
    return new Intl.DateTimeFormat('en-CA', { 
        timeZone: 'Europe/Kyiv', 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit' 
    }).format(new Date());
}
