<template>
    <v-card class="outlined-card">
      <v-card-title>
        <strong>{{ title }}</strong>
      </v-card-title>
      <v-list dense>
        <v-list-item
          v-for="position in positions"
          :key="position.id"
          @click="$emit('select', position)"
          :class="getItemClass(position)"
          class="position-item"
          ripple
        >
          <v-list-item-title>{{ position.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ position.description }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="position.isUnderAttack" class="under-attack-text blink">
            ⚠️ Штурмові дії ворога
          </v-list-item-subtitle>
          <v-list-item-subtitle v-if="position.isUnderFire" class="under-fire-text blink">
            ⚠️ Під обстрілом
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-card>
  </template>
  
  <script setup>
  defineProps({
    title: {
      type: String,
      required: true
    },
    positions: {
      type: Array,
      required: true
    }
  });
  
  defineEmits(['select']);
  
  function getItemClass(position) {
    if (position.isUnderAttack && position.isUnderFire) {
      return 'position-item-active under-attack under-fire';
    } else if (position.isUnderAttack) {
      return 'position-item-active under-attack';
    } else if (position.isUnderFire) {
      return 'position-item-active under-fire';
    }
    return '';
  }
  </script>
  
  <style scoped>
  .position-item {
    transition: background-color 0.3s;
  }
  
  .position-item-active.under-attack {
    animation: blink-bg-attack 1.5s infinite;
  }
  
  .position-item-active.under-fire {
    animation: blink-bg-fire 1.5s infinite;
  }
  
  .position-item-active.under-attack.under-fire {
    animation: blink-bg-both 1.5s infinite;
  }
  
  .position-item:hover {
    background-color: #f0f0f0;
  }
  
  .under-attack-text,
  .under-fire-text {
    color: red;
    font-weight: bold;
  }
  
  @keyframes blink-bg-attack {
    0%, 50%, 100% {
      background-color: #ffcccc;
    }
    25%, 75% {
      background-color: transparent;
    }
  }
  
  @keyframes blink-bg-fire {
    0%, 50%, 100% {
      background-color: #ffcccc;
    }
    25%, 75% {
      background-color: transparent;
    }
  }
  
  @keyframes blink-bg-both {
    0%, 50%, 100% {
      background-color: #ff9999;
    }
    25%, 75% {
      background-color: transparent;
    }
  }
  </style>