const result = {
  id: 388,
  name: "Результат",
  displayText: "Результат: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 389,
      name: "Успішно",
      displayText: "Успішно, ",
      disableLeftSpaces: true,
      children: [
        {
          id: 390,
          name: "Знищено",
          displayText: "знищено\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 391,
          name: "Пошкоджено",
          displayText: "пошкоджено\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 392,
          name: "Уражено",
          displayText: "уражено\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 393,
          name: "Влучання",
          displayText: "влучання\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 394,
          name: "Обстріляно",
          displayText: "обстріляно\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 395,
          name: "Вбито",
          displayText: "вбито\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 396,
          name: "Поранено",
          displayText: "поранено\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 397,
          name: "Інше",
          displayText: null,
          disableLeftSpaces: true,
          type: "text-area",
          skipMapStep: true,
          children: [],
        },
      ],
    },
    {
      id: 398,
      name: "Не успішно",
      displayText: "Не успішно, ",
      disableLeftSpaces: true,
      children: [
        {
          id: 399,
          name: "Втрата управління",
          displayText: "втрата управління\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 400,
          name: "Втрата відео",
          displayText: "втрата відео\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 401,
          name: "Не детон",
          displayText: "не детон\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 402,
          name: "Збили",
          displayText: "збили\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 403,
          name: "Погодні умови",
          displayText: "погодні умови\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 404,
          name: "Не влучили",
          displayText: "не влучили\n",
          disableLeftSpaces: true,
          skipMapStep: true,
          children: [],
        },
        {
          id: 405,
          name: "Інше",
          displayText: null,
          disableLeftSpaces: true,
          type: "text-area",
          skipMapStep: true,
          children: [],
        },
      ],
    },
  ],
};

const streamOrRecord = {
  id: 386,
  name: "Стрім/запис",
  displayText: "Стрім/запис: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 387,
      name: "Стрім/запис",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [result],
    },
  ],
};

const numberAmmunition = {
  id: 384,
  name: "Кількість БК",
  displayText: "Кількість БК: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 385,
      name: "Кількість БК",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [streamOrRecord],
    },
  ],
};

const combatUnit = {
  id: 374,
  name: "БЧ",
  displayText: "БЧ: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 375,
      name: "Уламкова",
      displayText: "Уламкова\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 376,
      name: "Фугасна",
      displayText: "Фугасна\n",
      disableLeftSpaces: true,
      skipMapStep: true,
      children: [],
    },
    {
      id: 377,
      name: "Уламково-фугасна",
      displayText: "Уламково-фугасна\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 378,
      name: "Кумулятивна",
      displayText: "Кумулятивна\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 379,
      name: "Кумулятивно-уламков",
      displayText: "Кумулятивно-уламков\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 380,
      name: "Термобарична",
      displayText: "Термобарична\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 381,
      name: "Запалювальна",
      displayText: "Запалювальна\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 382,
      name: "Газ",
      displayText: "Газ\n",
      disableLeftSpaces: true,
      children: [numberAmmunition],
    },
    {
      id: 383,
      name: "Інше",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [numberAmmunition],
    },
  ],
};

const coordinatesAndSquare = {
  id: 373,
  name: "Координати або квадрат",
  displayText: null,
  disableLeftSpaces: true,
  type: "coordinates-square",
  children: [combatUnit],
};

const mission = {
  id: 302,
  name: "Місія",
  displayText: "Місія: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 303,
      name: "Ударна",
      displayText: "Ударна\n",
      disableLeftSpaces: true,
      children: [
        {
          id: 304,
          name: "Ціль",
          displayText: "Ціль: ",
          disableLeftSpaces: true,
          children: [
            {
              id: 305,
              name: "Піхота",
              displayText: null,
              disableLeftSpaces: true,
              children: [
                {
                  id: 306,
                  name: "Піхота",
                  displayText: "піхота\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 307,
                  name: "Піхота у русі",
                  displayText: "піхота у русі\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 308,
                  name: "Піхота в укритті",
                  displayText: "піхота в укритті\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
              ],
            },
            {
              name: "Техніка",
              displayText: null,
              disableLeftSpaces: true,
              id: 309,
              children: [
                {
                  id: 310,
                  name: "ББМ",
                  displayText: "ББМ\n",
                  disableLeftSpaces: true,

                  children: [coordinatesAndSquare],
                },
                {
                  id: 311,
                  name: "ВАТ",
                  displayText: "ВАТ\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 312,
                  name: "ЛАТ",
                  displayText: "ЛАТ\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 313,
                  name: "БМП",
                  displayText: "БМП\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 314,
                  name: "БТР",
                  displayText: "БТР\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 315,
                  name: "МТЛБ",
                  displayText: "МТЛБ\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 316,
                  name: "Танк",
                  displayText: "Танк\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 317,
                  name: "Мотоцикл",
                  displayText: "Мотоцикл\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 318,
                  name: "Квадроцикл",
                  displayText: "Квадроцикл\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 319,
                  name: "Баггі",
                  displayText: "Баггі\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 320,
                  name: "Інженерна техніка",
                  displayText: "Інженерна техніка\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
              ],
            },
            {
              name: "Артилерійський засіб",
              displayText: null,
              disableLeftSpaces: true,
              id: 321,
              children: [
                {
                  id: 322,
                  name: "Артилерійський засіб",
                  displayText: "Артилерійський засіб\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 323,
                  name: "Міномет",
                  displayText: "Міномет\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 324,
                  name: "Міномет 82 мм",
                  displayText: "Міномет, 82 мм\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 325,
                  name: "Міномет 120 мм",
                  displayText: "Міномет, 120 мм\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 326,
                  name: "Гаубиця",
                  displayText: "Гаубиця\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 327,
                  name: "САУ",
                  displayText: "САУ\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
                {
                  id: 328,
                  name: "РСЗВ",
                  displayText: "РСЗВ\n",
                  disableLeftSpaces: true,
                  children: [coordinatesAndSquare],
                },
              ],
            },

            {
              id: 329,
              name: "СПГ",
              displayText: "СПГ\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },
            {
              id: 330,
              name: "АГС",
              displayText: "АГС\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },

            {
              id: 331,
              name: "Розрахунок БПЛА",
              displayText: "Розрахунок БПЛА\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },

            {
              id: 332,
              name: "Бліндаж/укриття",
              displayText: "Бліндаж/укриття\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },

            {
              id: 333,
              name: "Засіб РЕБ",
              displayText: "Засіб РЕБ\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },
            {
              id: 334,
              name: "Антена",
              displayText: "Антена\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },

            {
              id: 335,
              name: "ПТРК",
              displayText: "ПТРК\n",
              disableLeftSpaces: true,
              children: [coordinatesAndSquare],
            },
            {
              id: 336,
              name: "Склад",
              displayText: null,
              disableLeftSpaces: true,
              children: [
                {
                  id: 337,
                  name: "Склад",
                  displayText: "Склад\n",
                  disableLeftSpaces: true,

                  children: [coordinatesAndSquare],
                },
                {
                  id: 338,
                  name: "Склад БК",
                  displayText: "Склад БК\n",
                  disableLeftSpaces: true,

                  children: [coordinatesAndSquare],
                },
                {
                  id: 339,
                  name: "Склад ПММ",
                  displayText: "Склад ПММ\n",
                  disableLeftSpaces: true,

                  children: [coordinatesAndSquare],
                },
              ],
            },
            {
              id: 340,
              name: "Інша ціль",
              displayText: null,
              disableLeftSpaces: true,
              type: "text-area",
              children: [coordinatesAndSquare],
            },
          ],
        },
      ],
    },
    {
      id: 341,
      name: "Мінування",
      displayText: "Мінування\n",
      disableLeftSpaces: true,
      children: [
        {
          id: 342,
          name: "Координати або квадрат",
          displayText: null,
          disableLeftSpaces: true,
          type: "coordinates-square",
          children: [
            {
              id: 343,
              name: "Тип мін та кількість",
              displayText: "Тип мін та кількість: ",
              disableLeftSpaces: true,
              children: [
                {
                  id: 344,
                  name: "Тип мін та кількіст",
                  displayText: null,
                  disableLeftSpaces: true,
                  type: "text-area",
                  children: [
                    {
                      id: 345,
                      name: "Результат",
                      displayText: "Результат: ",
                      disableLeftSpaces: true,
                      children: [
                        {
                          id: 346,
                          name: "Заміновано",
                          displayText: "Заміновано\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 347,
                          name: "Втрата управління",
                          displayText: "Втрата управління\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 348,
                          name: "Втрата відео",
                          displayText: "Втрата відео\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 349,
                          name: "Збили",
                          displayText: "Збили\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 350,
                          name: "Погодні умови",
                          displayText: "Погодні умови\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 351,
                          name: "Не влучили",
                          displayText: "Не влучили\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 352,
                          name: "Інше",
                          displayText: null,
                          disableLeftSpaces: true,
                          type: "text-area",
                          skipMapStep: true,
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 353,
      name: "Доставка",
      displayText: "Доставка\n",
      disableLeftSpaces: true,
      children: [
        {
          id: 354,
          name: "Координати або квадрат",
          displayText: null,
          disableLeftSpaces: true,
          type: "coordinates-square",
          children: [
            {
              id: 355,
              name: "Навантаження",
              displayText: "Навантаження: ",
              disableLeftSpaces: true,
              children: [
                {
                  id: 356,
                  name: "Навантаження",
                  displayText: null,
                  disableLeftSpaces: true,
                  type: "text-area",
                  children: [
                    {
                      id: 357,
                      name: "Результат",
                      displayText: "Результат: ",
                      disableLeftSpaces: true,
                      children: [
                        {
                          id: 358,
                          name: "Доставлено",
                          displayText: "Доставлено\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 359,
                          name: "Втрата управління",
                          displayText: "Втрата управління\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 360,
                          name: "Втрата відео",
                          displayText: "Втрата відео\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 361,
                          name: "Збили",
                          displayText: "Збили\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 362,
                          name: "Погодні умови",
                          displayText: "Погодні умови\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 363,
                          name: "Не доставили",
                          displayText: "Не доставили\n",
                          disableLeftSpaces: true,
                          skipMapStep: true,
                          children: [],
                        },
                        {
                          id: 364,
                          name: "Інше",
                          displayText: null,
                          disableLeftSpaces: true,
                          type: "text-area",
                          skipMapStep: true,
                          children: [],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: 365,
      name: "РКС",
      displayText: "РКС\n",
      disableLeftSpaces: true,
      children: [
        {
          id: 366,
          name: "Результат",
          displayText: "Результат: ",
          disableLeftSpaces: true,
          children: [
            {
              id: 367,
              name: "Виконано",
              displayText: "Виконано\n",
              disableLeftSpaces: true,
              skipMapStep: true,
              children: [],
            },
            {
              id: 368,
              name: "Втрата управління",
              displayText: "Втрата управління\n",
              disableLeftSpaces: true,
              skipMapStep: true,
              children: [],
            },
            {
              id: 369,
              name: "Втрата відео",
              displayText: "Втрата відео\n",
              disableLeftSpaces: true,
              skipMapStep: true,
              children: [],
            },
            {
              id: 370,
              name: "Збили",
              displayText: "Збили\n",
              disableLeftSpaces: true,
              skipMapStep: true,
              children: [],
            },
            {
              id: 371,
              name: "Погодні умови",
              displayText: "Погодні умови\n",
              disableLeftSpaces: true,
              skipMapStep: true,
              children: [],
            },
            {
              id: 372,
              name: "Інше",
              displayText: null,
              disableLeftSpaces: true,
              type: "text-area",
              skipMapStep: true,
              children: [],
            },
          ],
        },
      ],
    },
  ],
};

const solution = {
  id: 289,
  name: "Засіб",
  displayText: "Засіб: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 290,
      name: "FPV-дрон",
      displayText: "FPV-дрон\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 291,
      name: "FPV-крило",
      displayText: "FPV-крило\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 292,
      name: "Mavic",
      displayText: "Mavic\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 293,
      name: "Кажан",
      displayText: "Кажан\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 294,
      name: "Stalker",
      displayText: "Stalker\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 295,
      name: "Lucky Strike",
      displayText: "Lucky Strike\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 296,
      name: "Vampire",
      displayText: "Vampire\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 297,
      name: "Heavy Shot",
      displayText: "Heavy Shot\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 298,
      name: "Nemesis",
      displayText: "Nemesis\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 299,
      name: "Сич",
      displayText: "Сич\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 300,
      name: "Warmate",
      displayText: "Warmate\n",
      disableLeftSpaces: true,
      children: [mission],
    },
    {
      id: 301,
      name: "Інше",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [mission],
    },
  ],
};

const departure = {
  id: 288,
  name: "Виліт",
  displayText: "Виліт: ",
  disableLeftSpaces: true,
  children: [
    {
      id: 407,
      name: "Виліт",
      displayText: null,
      disableLeftSpaces: true,
      type: "text-area",
      children: [solution],
    },
  ],
};

export default {
  id: 406,
  name: "Виконано завдання (БПЛА)",
  displayText: "Виконано завдання (БПЛА)\n\n",
  disableLeftSpaces: true,
  children: [departure],
};
