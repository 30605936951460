<template>
    <v-dialog :model-value="dialogVisible" @update:model-value="$emit('update:dialogVisible', $event)" max-width="500px"
        @click:outside="handleClose">
        <v-card>
            <v-card-title>
                {{ isEditing ? 'Редагувати Підрозділ' : 'Створити Підрозділ' }}
            </v-card-title>

            <v-card-text>
                <v-alert v-if="alert.show" :type="alert.type" dismissible @input="alert.show = false"
                    transition="scale-transition">
                    {{ alert.message }}
                </v-alert>

                <v-form ref="formRef" v-model="formValid">
                    <v-text-field v-model="localForm.name" label="Назва" :rules="[v => !!v || 'Назва обовязкова']"
                        required />

                    <!-- Signal Chats Section -->
                    <div class="chat-section">
                        <h3>Signal чати</h3>
                        <v-text-field v-model="newSignalChat" label="Додати Signal чат"
                            @keyup.enter="handleAddSignalChat" @blur="handleAddSignalChat" />

                        <div v-if="localForm.signalChatsData.length" class="chat-chips">
                            <v-chip v-for="chat in localForm.signalChatsData" :key="`signal-${chat.name}`" closable
                                @click:close="handleRemoveChat('signalChatsData', chat)" class="ma-1">
                                {{ chat.name }}
                            </v-chip>
                        </div>
                    </div>

                    <!-- WhatsApp Chats Section -->
                    <div class="chat-section">
                        <h3>WhatsApp чати</h3>
                        <v-text-field v-model="newWhatsappChat" label="Додати WhatsApp чат"
                            @keyup.enter="handleAddWhatsappChat" @blur="handleAddWhatsappChat" />

                        <div v-if="localForm.whatsappChatsData.length" class="chat-chips">
                            <v-chip v-for="chat in localForm.whatsappChatsData" :key="`whatsapp-${chat.name}`" closable
                                @click:close="handleRemoveChat('whatsappChatsData', chat)" class="ma-1">
                                {{ chat.name }}
                            </v-chip>
                        </div>
                    </div>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn class="custom-red-btn" @click="handleClose">
                    Відмінити
                </v-btn>
                <v-btn class="custom-green-btn" @click="handleSave" :disabled="!formValid">
                    Зберегти
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';

interface Props {
    dialogVisible: boolean;
    isEditing: boolean;
    form: FormState['battalion'];
}

const props = defineProps<Props>();
const emit = defineEmits<{
    (e: 'update:dialogVisible', value: boolean): void;
    (e: 'save', isEdit: boolean, formData: FormState['battalion']): void;
    (e: 'close'): void;
}>();

const formRef = ref<any>(null);
const formValid = ref(false);

const localForm = reactive({
    id: null as string | null,
    name: '',
    signalChatsData: [] as { name: string; config: any }[],
    whatsappChatsData: [] as { name: string; config: any }[]
});

watch(
    () => props.form,
    (newForm) => {
        localForm.id = newForm.id;
        localForm.name = newForm.name;
        localForm.signalChatsData = [...newForm.signalChatsData]
        localForm.whatsappChatsData = [...newForm.whatsappChatsData]
    },
    { immediate: true, deep: true }
);

const alert = ref({
    show: false,
    type: 'error' as const,
    message: ''
});

const newSignalChat = ref('');
const newWhatsappChat = ref('');

function showAlert(message: string, type: 'error' | 'success' = 'error') {
    alert.value = {
        show: true,
        type,
        message
    };

    setTimeout(() => {
        alert.value.show = false;
    }, 3000);
}

function handleAddSignalChat() {
    const trimmedChat = newSignalChat.value.trim();
    if (trimmedChat && !localForm.signalChatsData.some(chat => chat.name === trimmedChat)) {
        localForm.signalChatsData.push({ name: trimmedChat, config: null });
        newSignalChat.value = '';
        console.log('Added to signalChatsData:', localForm.signalChatsData); // Debug log for added chat
    }
}

function handleAddWhatsappChat() {
    const trimmedChat = newWhatsappChat.value.trim();
    if (trimmedChat && !localForm.whatsappChatsData.some(chat => chat.name === trimmedChat)) {
        localForm.whatsappChatsData.push({ name: trimmedChat, config: null });
        newWhatsappChat.value = '';
        console.log('Added to whatsappChatsData:', localForm.whatsappChatsData); // Debug log for added chat
    }
}

function handleRemoveChat(type: 'signalChatsData' | 'whatsappChatsData', chat: { name: string; config: any }) {
    localForm[type] = localForm[type].filter(c => c.name !== chat.name);
    console.log(`Removed from ${type}:`, localForm[type]); // Debug log for removed chat
}

async function handleSave() {
    const { valid } = await formRef.value?.validate();

    if (!valid) {
        showAlert('Будь ласка, заповніть всі обовязкові поля');
        return;
    }

    // Convert reactive proxy to plain object
    const plainLocalForm = JSON.parse(JSON.stringify(localForm));
    console.log('Form data before save (plain object):', plainLocalForm);

    emit('save', props.isEditing, plainLocalForm);
}

function handleClose() {
    emit('update:dialogVisible', false);
    emit('close');
    formRef.value?.resetValidation();
}
</script>

<style scoped>
.chat-section {
    margin-top: 20px;
}

.chat-chips {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
}

.custom-green-btn {
    background-color: #4CAF50;
    color: white;
}

.custom-red-btn {
    background-color: #D32F2F;
    color: white;
}
</style>
