<template>
  <v-form @submit.prevent="handleSubmit">
    <v-text-field
      v-model="form.username"
      label="Користувач"
      placeholder="Введіть імʼя користувача"
      prepend-icon="mdi-account"
      :error-messages="errors.username"
      :disabled="loading"
      required
    />
    <v-text-field
      v-model="form.password"
      label="Пароль"
      placeholder="Введіть пароль користувача"
      type="password"
      prepend-icon="mdi-lock"
      :error-messages="errors.password"
      :disabled="loading"
      required
    />
    <v-btn
      :disabled="!isValid || loading"
      type="submit"
      color="primary"
      block
      class="custom-btn"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="white"
        size="20"
      />
      <span v-else>Вхід</span>
    </v-btn>
    
    <v-alert
      v-if="showAlert"
      type="error"
      dismissible
      @update:modelValue="$emit('update:showAlert', $event)"
      :modelValue="showAlert"
      class="mt-3"
    >
      {{ errorMessage }}
    </v-alert>
  </v-form>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useForm } from '@/composables/useForm';

const props = defineProps({
  loading: Boolean,
  errorMessage: String,
  showAlert: Boolean,
});

const emit = defineEmits(['submit', 'update:showAlert']);

const { form, errors, validate, resetForm } = useForm({
  initialValues: {
    username: '',
    password: '',
  },
  validationRules: {
    username: (value) => !!value.trim() || 'Заповніть поле користувача.',
    password: (value) => !!value || 'Заповніть поле пароля.',
  },
});

const isValid = computed(() => 
  form.username.trim() && form.password && Object.keys(errors).length === 0
);

function handleSubmit() {
  if (validate()) {
    emit('submit', { 
      username: form.username, 
      password: form.password 
    });
  }
}
</script>