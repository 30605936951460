<template>
  <v-dialog 
    :model-value="dialogVisible"
    @update:model-value="$emit('update:dialog-visible', $event)"
    max-width="600px"
  >
    <v-card>
      <v-toolbar flat>
          <v-toolbar-title>Події для чату {{ chatName }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          class="ma-5"
        ></v-progress-circular>

        <v-treeview
          v-else
          :key="selectionKey"
          v-model:selected="selection"
          :items="items"
          select-strategy="classic"
          item-title="name"
          item-value="id"
          return-object
          selectable
          dense
          @update:selected="onSelectionUpdate"
        ></v-treeview>
      </v-card-text>

      <v-card-actions>
        <v-btn class="custom-red-btn" @click="handleClose">Відмінити</v-btn>
        <v-btn class="custom-green-btn" @click="handleSave">Зберегти</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import fullChatsConfig from '@/config/eventConfig';
const chatsConfig = {
  //removing extra fields to optimize rendering
  // this very bad approach, need to be optimized
  eventTypes: fullChatsConfig.eventTypes.map((evt) => {
    if (['Виконано вогневе завдання', 'Виконано завдання (БПЛА)', 'Бойова готовність'].includes(evt.name)) {
      return {
        id: evt.id,
        name: evt.name,
      }
    } else return evt;
  })
}

interface TreeItem {
  id: string | number;
  name: string;
  children?: TreeItem[];
}

const props = defineProps<{
  dialogVisible: boolean;
  chatName: string;
  chatType: 'signal' | 'whatsapp';
  config: any | null;
}>();

const emit = defineEmits<{
    (e: 'update:dialog-visible', value: boolean): void;
  (e: 'save', config: any[]): void;
}>();

const selection = ref<TreeItem[]>([]);
const items = ref<TreeItem[]>([]);
const loading = ref(true);
const selectionKey = ref(0);

const loadAndPrepareData = () => {
  // Ensure chatsConfig.eventTypes exists and is an array
  items.value = Array.isArray(chatsConfig.eventTypes) ? chatsConfig.eventTypes : [];

  if (props.config && Array.isArray(props.config)) {
    preselectNodes(props.config);
  } else {
    selectAllNodes(items.value);
  }
};

const onSelectionUpdate = (newSelection: TreeItem[]) => {
  if (Array.isArray(newSelection)) {
    selection.value = newSelection;
  }
};

const handleSave = () => {
  const selectedNodes = selection.value.map(node => ({
    id: node.id,
    name: node.name
  }));

  emit('save', selectedNodes);
};

const handleClose = () => {
    emit('update:dialog-visible', false);
};

const preselectNodes = (config: any[]) => {
  if (!Array.isArray(config)) return;

  const validConfig = config.filter(item => item && 
    typeof item === 'object' && 
    'id' in item && 
    item.id !== undefined && 
    item.id !== null
  );

  const selectedIds = validConfig.map(item => item.id);
  
  const selectNodes = (nodes: TreeItem[]) => {
    if (!Array.isArray(nodes)) return;

    nodes.forEach(node => {
      if (selectedIds.includes(node.id)) {
        if (!selection.value.find(selectedNode => selectedNode.id === node.id)) {
          selection.value.push(node);
        }
      }
      if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        selectNodes(node.children);
      }
    });
  };

  selectNodes(items.value);
  selection.value = [...selection.value];
  selectionKey.value++;
  
  // Using nextTick-like behavior
  setTimeout(() => {
    items.value = [...items.value];
  });
};

const selectAllNodes = (nodes: TreeItem[]) => {
  if (!Array.isArray(nodes)) return;

  const selectAll = (nodesToSelect: TreeItem[]) => {
    nodesToSelect.forEach(node => {
      if (!selection.value.find(selectedNode => selectedNode.id === node.id)) {
        selection.value.push(node);
      }
      if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        selectAll(node.children);
      }
    });
  };

  selectAll(nodes);
  selectionKey.value++;
  
  setTimeout(() => {
    items.value = [...items.value];
  });
};

// Watch for changes in config prop
watch(() => props.config, (newConfig) => {
  selection.value = [];
  loadAndPrepareData();
}, { deep: true });

onMounted(() => {
  loadAndPrepareData();
  loading.value = false;
});
</script>

<style scoped>
.custom-green-btn {
  background-color: #4CAF50;
  color: white;
}

.custom-red-btn {
  background-color: #D32F2F;
  color: white;
}
</style>
