<template>
    <v-dialog :model-value="dialogVisible" @update:model-value="$emit('update:dialog-visible', $event)"
        max-width="500px">
        <v-card>
            <v-card-title>
                {{ isEditing ? 'Оновити користувача' : 'Створити користувача' }}
            </v-card-title>

            <v-card-text>
                <v-alert v-if="alert.show" :type="alert.type" dismissible @input="alert.show = false"
                    transition="scale-transition">
                    {{ alert.message }}
                </v-alert>

                <v-form ref="formRef" v-model="formValid">
                    <v-text-field v-model="localForm.username" label="Ім'я користувача"
                        :rules="[v => !!v || 'Імя користувача обовязкове']" required />

                    <v-text-field v-model="localForm.password" label="Пароль" type="password"
                        :rules="isEditing ? [] : [v => !!v || 'Пароль обовязковий']"
                        :placeholder="isEditing ? 'Залиште порожнім для незмінного пароля' : ''" required />

                    <v-select v-model="localForm.role" label="Роль" :items="availableRoles" item-title="displayName"
                        item-value="value" :rules="[v => !!v || 'Роль обовязкова']" required />

                    <v-text-field v-model="localForm.callsign" label="Позивний"
                        :rules="[v => !!v || 'Позивний обовязковий']" required />

                    <v-select v-model="localForm.squadronId" label="Пункт управління" :items="availableSquadrons"
                        item-title="display" item-value="id" :rules="[v => !!v || 'Пункт управління обовязковий']" required />
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer />
                <v-btn class="custom-red-btn" @click="handleClose">
                    Відмінити
                </v-btn>
                <v-btn class="custom-green-btn" @click="handleSave" :disabled="!formValid">
                    {{ isEditing ? 'Оновити' : 'Створити' }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';

interface Props {
  dialogVisible: boolean;
  isEditing: boolean;
  form: FormState['user'];
  availableSquadrons: Array<{ id: string; display: string }>;
  availableRoles: Array<{ value: string; displayName: string }>;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'update:dialog-visible', value: boolean): void;
  (e: 'save', isEdit: boolean, formData: FormState['user']): void;
}>();

const formRef = ref<any>(null);
const formValid = ref(false);

const localForm = reactive({ ...props.form });

// Watch for external form changes
watch(
  () => props.form,
  (newForm) => {
    Object.keys(newForm).forEach((key) => {
      localForm[key] = newForm[key];
    });
  },
  { deep: true }
);

const alert = ref({
  show: false,
  type: 'error' as const,
  message: ''
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.value = {
    show: true,
    type,
    message
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  // Emit the save event with updated form data
  emit('save', props.isEditing, { ...localForm });
}

function handleClose() {
  emit('update:dialog-visible', false);
}

// Reset form validation when dialog opens
watch(
  () => props.dialogVisible,
  (newVal) => {
    if (newVal) {
      formRef.value?.resetValidation();
    }
  }
);
</script>
