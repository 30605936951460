import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from "vue";
import { useEventCreationStore } from "@/stores/eventCreation";


export default /*@__PURE__*/_defineComponent({
  __name: 'EventTextArea',
  props: {
    title: {},
    lastItem: { type: Boolean }
  },
  emits: ["select"],
  setup(__props: any, { emit: __emit }) {

const eventStore = useEventCreationStore();
const emit = __emit;

const props = __props;

const textarea = ref("");
const disabled = computed(() => !textarea.value);

function onKeydown(e: KeyboardEvent) {
  e.stopPropagation();
}

function proceed() {
  eventStore.$patch({
    displayedDescription:
      (eventStore.displayedDescription += `${textarea.value}\n`),
  });

  emit("select");
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            label: _ctx.title,
            modelValue: textarea.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((textarea).value = $event)),
            variant: "filled",
            "single-line": "",
            onKeydown: onKeydown
          }, null, 8, ["label", "modelValue"])
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "custom-green-btn",
            onClick: proceed,
            disabled: disabled.value
          }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})