<template>
  <v-dialog :model-value="dialog" @update:model-value="handleDialogUpdate" max-width="600">
    <v-card>
      <v-card-title>
        <v-row justify="end">
        <v-icon class="custom-red-btn" @click="closeDialog" color="red-darken-4" icon="mdi-close"></v-icon>
      </v-row>
        <span class="headline">{{ dialogTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-alert v-if="alert.show" :type="alert.type" dismissible @update:modelValue="alert.show = false">
          {{ alert.message }}
        </v-alert>

        <event-type-selector v-if="isSelectionStep" :items="currentItems" :selection-type="eventStore.currentStep"
          :allow-multiple="Boolean(eventStore.selectedSubtype?.is_multichoice)" :dialog="dialog" @select="handleSelection" />

        <map-selection-view v-if="eventStore.currentStep === 'map'" :dialog="dialog" :items="currentItems"
          @location-selected="handleLocationSelected" @update:dialog="handleMapDialogClose" />

        <event-notes-form v-else-if="eventStore.currentStep === 'notes'" v-model="eventStore.notes"
          :show-allies="Boolean(eventStore.selectedType?.allies)"
          :show-enemies="Boolean(eventStore.selectedType?.enemies)" />
      </v-card-text>

      <v-card-actions v-if="eventStore.currentStep === 'notes'">
        <v-spacer />
        <v-btn :disabled="eventStore.loading" class="custom-green-btn-x2"
          @click="handleSubmit">
          CТВОРИТИ ПОДІЮ
        </v-btn>
       
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, watch, nextTick } from 'vue';
import type { EventType, Position, EventLocation } from '@/types/events';
import { useEventCreationStore } from '@/stores/eventCreation';
import eventConfig from '@/config/eventConfig';
import EventTypeSelector from '@/components/EventCreation/EventTypeSelector.vue';
import MapSelectionView from '@/components/MapSelectionView.vue';
import EventNotesForm from '@/components/EventCreation/EventNotesForm.vue';

const props = defineProps<{
  dialog: boolean;
  position: Position;
}>();

const emit = defineEmits<{
  'update:dialog': [value: boolean];
  'event-success': [message: string];
  'event-error': [message: string];
  'close': [];
  'select': [selection: EventType | string[] | string];
}>();

const eventStore = useEventCreationStore();

const positionData = ref({ ...props.position });
const alert = ref({ show: false, message: '', type: 'info' });

const isSelectionStep = computed(() =>
  ['type', 'subtype', 'sub-subtype'].includes(eventStore.currentStep)
);

const dialogTitle = computed(() => {
  switch (eventStore.currentStep) {
    case 'type':
      return 'Оберіть тип';
    case 'subtype':
      return `Оберіть для ${eventStore.selectedType?.name}`;
    case 'sub-subtype':
      return `Оберіть для ${eventStore.selectedSubtype?.name}`; 
    case 'coordinates-square':
      return `Визначте ${getLastSelectedName()}`;
    case 'expenditure-balance':
      return `Визначте ${getLastSelectedName()}`;
    case 'text-area':
      return `Введіть ${getLastSelectedName()}`;
    case 'map':
      return `Визначте координати для ${getLastSelectedName()}`;
    case 'notes':
      return `Додатково для ${getLastSelectedName()}`;
    default:
      return 'Створення події';
  }
});

const currentItems = computed(() => {
  switch (eventStore.currentStep) {
    case 'type':
      return eventConfig.eventTypes;
    case 'subtype':
      return eventStore.selectedType?.children || [];
    case 'sub-subtype':
      return eventStore.selectedSubtype?.children ||
        (eventStore.selectedType?.children?.find(child =>
          child.id === eventStore.selectedSubtype?.id
        ))?.children || [];
    default:
      return [];
  }
});

function getLastSelectedName(): string {
  return eventStore.selectedSubSubtype ||
    eventStore.selectedSubtype?.name ||
    eventStore.selectedType?.name || '';
}

function handleSelection(selection: EventType | string[] | string) {
  if (Array.isArray(selection)) {
    eventStore.selectedSubSubtypes = selection;
    if (selection.length > 0) {
      eventStore.determineNextStep(eventStore.selectedSubtype!);
    }
  } else if (typeof selection === 'string') {
    eventStore.selectedSubSubtype = selection;
    eventStore.determineNextStep(eventStore.selectedSubtype!);
  } else {
    if (eventStore.currentStep === 'type') {
      eventStore.selectType(selection);
    } else {
      eventStore.selectSubtype(selection);
    }
  }
}

async function handleLocationSelected(location: EventLocation) {
  console.log('Received location data:', location);
  
  eventStore.$patch({ 
    location,
    currentStep: 'notes'
  });
}

async function handleSubmit() {
  const result = await eventStore.createEvent(positionData.value);

  console.log('positon', props.position);
  if (result.success) {
    emit('event-success', result.message);
    closeDialog();
  } else {
    showAlert(result.message, 'error');
  }
}

function showAlert(message: string, type: string = 'info') {
  alert.value = {
    show: true,
    message,
    type
  };
}

function handleDialogUpdate(value: boolean) {
  if (!value) {
    closeDialog();
  }
}

const handleMapDialogClose = (value) => {
  if (!value) {
    eventStore.reset();
    emit('update:dialog', false);
    emit('close');
  }
}

function closeDialog() {
  nextTick(() => {
    eventStore.reset();
    emit('update:dialog', false);
    emit('close');
  });
}

// Watch for dialog prop changes to ensure cleanup
watch(() => props.dialog, (newValue) => {
  if (!newValue) {
    closeDialog();
  }
});

watch(() => props.position, (newValue) => {
  positionData.value = { ...newValue };
});

</script>

<style scoped>
.headline {
  font-weight: bold;
}

.custom-green-btn-x2 {
  background-color: #4caf50;
  color: white;
  padding: 0 32px;
}

.custom-red-btn {
  background-color: #f44336;
  color: white;
  margin-left: 8px;
  margin-top: 8px; 
}
</style>