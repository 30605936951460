import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, reactive, watch } from 'vue';
  import type { FormState } from '@/types/admin';
  
  interface Props {
    dialogVisible: boolean;
    isEditing: boolean;
    form: FormState['squadron'];
    availableBattalions: Array<{ id: string; name: string }>;
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'CreateEditSquadronDialog',
  props: {
    dialogVisible: { type: Boolean },
    isEditing: { type: Boolean },
    form: {},
    availableBattalions: {}
  },
  emits: ["update:dialogVisible", "close", "save"],
  setup(__props: any, { emit: __emit }) {

  const props = __props;
  
  const emit = __emit;
  
  const formRef = ref<any>(null);
  const formValid = ref(false);
  
  const localForm = reactive(props.form);
  
  const alert = ref({
    show: false,
    type: 'error' as const,
    message: ''
  });
  
  function showAlert(message: string, type: 'error' | 'success' = 'error') {
    alert.value = {
      show: true,
      type,
      message
    };
  
    setTimeout(() => {
      alert.value.show = false;
    }, 3000);
  }
  
  async function handleSave() {
    const { valid } = await formRef.value?.validate();
    
    if (!valid) {
      showAlert('Будь ласка, заповніть всі обовязкові поля');
      return;
    }
  
    emit('save', props.isEditing, localForm);
  }
  
  function handleClose() {
    emit('update:dialogVisible', false);
    emit('close');
  }
  
  // Reset form validation when dialog opens
  watch(() => props.dialogVisible, (newVal) => {
    if (newVal) {
      formRef.value?.resetValidation();
    }
  });
  
return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_select = _resolveComponent("v-select")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:dialogVisible', $event))),
    "max-width": "500px",
    "onClick:outside": handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isEditing ? 'Редагувати Пункт управління' : 'Створити Пункт управління'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (alert.value.show)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: alert.value.type,
                    dismissible: "",
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (alert.value.show = false)),
                    transition: "scale-transition"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(alert.value.message), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_form, {
                ref_key: "formRef",
                ref: formRef,
                modelValue: formValid.value,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((formValid).value = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localForm.name) = $event)),
                    label: "Назва",
                    rules: [v => !!v || 'Назва обовязкова'],
                    required: ""
                  }, null, 8, ["modelValue", "rules"]),
                  _createVNode(_component_v_select, {
                    modelValue: localForm.battalionId,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((localForm.battalionId) = $event)),
                    label: "Підрозділ",
                    items: _ctx.availableBattalions,
                    "item-title": "name",
                    "item-value": "id",
                    rules: [v => !!v || 'Підрозділ обовязковий'],
                    required: ""
                  }, null, 8, ["modelValue", "items", "rules"])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "custom-red-btn",
                onClick: handleClose
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Відмінити ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn",
                onClick: handleSave,
                disabled: !formValid.value
              }, {
                default: _withCtx(() => _cache[6] || (_cache[6] = [
                  _createTextVNode(" Зберегти ")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})