import { defineStore } from 'pinia';
import apiClient from '@/services/axios';
import User from '@/models/User';
import { useAdminStore } from '@/stores/admin';


export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: JSON.parse(localStorage.getItem('user')) || null,
    token: localStorage.getItem('access_token') || null,
  }),

  getters: {
    isAuthenticated: (state) => !!state.token,
    getUser: (state) => state.user,
    getToken: (state) => state.token,
  },

  actions: {
    async login(username, password) {
    const adminStore = useAdminStore();

      try {
        const authResponse = await apiClient.login({ username, password });

        if (!authResponse.data?.access_token) {
          throw new Error('Login failed, access_token not found.');
        }

        this.setToken(authResponse.data.access_token);

        await adminStore.fetchAllData();
        const userResponse = await apiClient.getMe();

        if (!userResponse.data) {
          throw new Error('Failed to retrieve user data.');
        }
    
        this.setUser(new User(userResponse.data));
        adminStore.$patch({ currentUser: userResponse.data });

        return true;
      } catch (error) {
        this.clearAuth();
        throw error;
      }
    },

    setToken(token) {
      this.token = token;
      localStorage.setItem('access_token', token);
    },

    setUser(user) {
      this.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },

    clearAuth() {
      this.token = null;
      this.user = null;
      localStorage.removeItem('access_token');
      localStorage.removeItem('user');
    },

    logout() {
      this.clearAuth();
    },

    // Optional: Method to check and refresh token status on app startup
    initializeAuth() {
      const token = localStorage.getItem('access_token');
      const user = localStorage.getItem('user');
      
      if (token) {
        this.token = token;
      }
      
      if (user) {
        try {
          this.user = JSON.parse(user);
        } catch (e) {
          this.clearAuth();
        }
      }
    },
  },
});
