<template>
  <v-dialog :model-value="dialog" @update:model-value="handleDialogUpdate" max-width="800">
    <v-card>
      <v-card-title>
        <span class="headline">Визначте координати</span>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="activePanel" accordion>
          <!-- Distance and Azimuth Panel -->
          <v-expansion-panel>
            <v-expansion-panel-title class="header">
              <span class="header-text">Азимут та дистанція події</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <!-- Distance Selector -->
              <div class="distance-section" v-if="!isDistanceUnknown">
                <div>Дистанція: {{ distance }} m</div>
                <distance-slider v-model="distance" :disabled="isDistanceUnknown" :tick-marks="[
                  { value: 0, label: '0' },
                  { value: 2500, label: '2500' },
                  { value: 5000, label: '>5000' },
                ]" />
              </div>

              <div class="input-section">
                <v-text-field label="Дистанція" v-model="distance" type="text" class="distance-input" hide-details
                  @input="onDistanceChange" :disabled="isDistanceUnknown" />
                <div class="checkbox-wrapper">
                  <v-checkbox v-model="isDistanceUnknown" label="Не можливо визначити" hide-details
                    @change="handleDistanceUnknownChange" />
                </div>
                <div v-if="distanceError" class="error-text">Дистанція некоректна</div>

                <div class="helper-text">
                  Формат азимуту: кілька значень через кому,
                  діапазон через тире, наприклад 70-75, 250, 40-50
                </div>
                <v-text-field label="Азимут" v-model="azimuth" type="text" class="azimuth-input" hide-details
                  @input="onAzimuthChange" :disabled="isAzimuthUnknown" />
                <div class="checkbox-wrapper">
                  <v-checkbox v-model="isAzimuthUnknown" label="Не можливо визначити" hide-details
                    @change="handleAzimuthUnknownChange" />
                </div>
                <div v-if="azimuthError" class="error-text">Азимут некоректний</div>
              </div>
              <div class="azimuth-section" v-if="!isAzimuthUnknown">
                <svg class="azimuth-circle" viewBox="0 0 200 200" @click="setAzimuth">
                  <!-- Dotted lines - always show -->
                  <line v-for="angle in markerAngles" :key="angle" :x1="100" :y1="100"
                    :x2="100 + 80 * Math.cos((angle - 90) * Math.PI / 180)"
                    :y2="100 + 80 * Math.sin((angle - 90) * Math.PI / 180)" stroke="black" stroke-dasharray="4" />
                  <!-- Degree markers - always show -->
                  <text v-for="angle in markerAngles" :key="'text' + angle"
                    :x="100 + 90 * Math.cos((angle - 90) * Math.PI / 180)"
                    :y="100 + 90 * Math.sin((angle - 90) * Math.PI / 180)" font-size="12" text-anchor="middle"
                    dominant-baseline="middle">
                    {{ angle }}
                  </text>
                  <!-- Azimuth arrow - only show when we have valid numeric azimuth -->
                  <template v-if="showAzimuthArrow">
                    <line :x1="100" :y1="100" :x2="100 + 80 * Math.cos((Number(azimuth) - 90) * Math.PI / 180)"
                      :y2="100 + 80 * Math.sin((Number(azimuth) - 90) * Math.PI / 180)" stroke="black"
                      stroke-width="2" />
                    <polygon :points="getArrowHeadPoints()" fill="black"
                      :transform="'rotate(180 ' + (100 + 80 * Math.cos((Number(azimuth) - 90) * Math.PI / 180)) + ' ' + (100 + 80 * Math.sin((Number(azimuth) - 90) * Math.PI / 180)) + ')'" />
                  </template>
                </svg>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>

          <!-- Map Panel -->
          <v-expansion-panel>
            <v-expansion-panel-title class="header">
              <span class="header-text">Вибрати на мапі</span>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="map-container">
                <l-map v-if="mapVisible" :zoom="zoom" :center="center" ref="map" @ready="onMapReady" @click="onMapClick"
                  style="height: 500px; width: 100%;">
                  <l-tile-layer :url="imageryUrl" :attribution="imageryAttribution"></l-tile-layer>
                  <l-tile-layer :url="labelsUrl" :attribution="labelsAttribution"></l-tile-layer>
                  <l-marker v-if="marker" :lat-lng="marker"></l-marker>
                </l-map>
              </div>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-btn class="custom-default-btn" @click="skip">Пропустити</v-btn>
        <v-btn class="custom-green-btn" @click="proceed" :disabled="proceedDisabledButton">Далі</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch, nextTick, computed } from "vue";
import { LMap, LTileLayer, LMarker } from "vue3-leaflet";
import type { LatLng } from 'leaflet';
import "leaflet/dist/leaflet.css";
import DistanceSlider from "./DistanceSlider.vue";

interface Props {
  dialog: boolean;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  'update:dialog': [value: boolean];
  'location-selected': [data: {
    distance: number | string;
    azimuth: number | string;
    coordinates: LatLng | { lat: number; lng: number };
  }];
}>();

// Refs
const activePanel = ref(0);
const zoom = ref(15);
const center = ref<[number, number]>([48.0159416, 37.7962839]);
const marker = ref<LatLng | null>(null);
const map = ref<any>(null);
const mapVisible = ref(false);

const distance = ref('0');
const azimuth = ref('0');
const azimuthError = ref(false);
const distanceError = ref(false);
const isDistanceUnknown = ref(false);
const isAzimuthUnknown = ref(false);
const proceedDisabledButton = ref(false);

// Map constants
const imageryUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
const imageryAttribution = "Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community";
const labelsUrl = "https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}";
const labelsAttribution = "Tiles &copy; Esri";
const markerAngles = [0, 90, 180, 270];

// Map methods
const onMapReady = (mapInstance: any) => {
  map.value = mapInstance;
  loadLastLocation();
};

const loadLastLocation = () => {
  try {
    const lastLocationStr = localStorage.getItem("lastLocation");
    if (lastLocationStr) {
      const lastLocation = JSON.parse(lastLocationStr);
      center.value = [lastLocation.lat, lastLocation.lng];
      panToLocation(center.value);
    } else {
      getUserLocation();
    }
  } catch (error) {
    console.error('Error loading last location:', error);
    getUserLocation();
  }
};

const handleDistanceUnknownChange = () => {
  if (isDistanceUnknown.value) {
    distance.value = 'не можливо визначити';
    distanceError.value = false;
  } else {
    distance.value = '0';
  }
};

const handleAzimuthUnknownChange = () => {
  if (isAzimuthUnknown.value) {
    azimuth.value = 'не можливо визначити';
    azimuthError.value = false;
  } else {
    azimuth.value = '0';
  }
};

const getUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        center.value = [userLocation.lat, userLocation.lng];
        panToLocation(center.value);
      },
      () => {
        alert("Неможливо отримати ваші координати, оберіть точку вручну");
      }
    );
  }
};

const panToLocation = (location: [number, number]) => {
  if (map.value) {
    map.value.setView(location, zoom.value);
  }
};

const onMapClick = (event: { latlng: LatLng }) => {
  marker.value = event.latlng;
  center.value = [event.latlng.lat, event.latlng.lng];
  localStorage.setItem("lastLocation", JSON.stringify(marker.value));
};

// Validation methods
const validateAzimuth = () => {
  if (isAzimuthUnknown.value) {
    azimuthError.value = false;
    return;
  }

  const azimuthValue = azimuth.value.toString();
  // const singleAzimuthPattern = /^\d{1,3}$/;
  // const rangeAzimuthPattern = /^\d{1,3}-\d{1,3}$/;
  const singleAzimuthPattern = /^\s*\d{1,3}\s*$/
  const rangeAzimuthPattern = /^\s*\d{1,3}\s*-\s*\d{1,3}\s*$/;


  const azimuthParts = azimuthValue.split(',').map(part => part.trim());

  azimuthError.value = azimuthParts.some(part => {
    if (rangeAzimuthPattern.test(part)) {
      const [minAzimuth, maxAzimuth] = part.split('-').map(val => parseFloat(val.trim()));
      return isNaN(minAzimuth) || isNaN(maxAzimuth) ||
        minAzimuth < 0 || minAzimuth > 360 || maxAzimuth < 0 || maxAzimuth > 360;
    } else if (singleAzimuthPattern.test(part)) {
      const parsedAzimuth = parseFloat(part);
      return isNaN(parsedAzimuth) || parsedAzimuth < 0 || parsedAzimuth > 360;
    }
    return true;
  });
  azimuth.value = azimuthValue.replace(/\s/g, '');
};

const validateDistance = () => {
  if (isDistanceUnknown.value) {
    distanceError.value = false;
    return;
  }
  const parsedDistance = parseFloat(distance.value);
  distanceError.value = isNaN(parsedDistance) || parsedDistance < 0 || !/^\d+$/.test(distance.value);
};

const onAzimuthChange = () => {
  validateAzimuth();
};

const onDistanceChange = () => {
  validateDistance();
};

const showAzimuthArrow = computed(() => {
  return !isAzimuthUnknown.value && !isNaN(Number(azimuth.value));
});

// Azimuth circle methods
const setAzimuth = (event: MouseEvent) => {
  if (isAzimuthUnknown.value) return;

  const target = event.target as SVGElement;
  const circleRect = target.getBoundingClientRect();
  const centerX = circleRect.left + circleRect.width / 2;
  const centerY = circleRect.top + circleRect.height / 2;

  const deltaX = event.clientX - centerX;
  const deltaY = event.clientY - centerY;

  const radians = Math.atan2(deltaY, deltaX);
  let degrees = radians * (180 / Math.PI);

  degrees = (degrees + 90 + 360) % 360;
  azimuth.value = Math.round(degrees).toString();
  validateAzimuth();
};

const getArrowHeadPoints = () => {
  if (isAzimuthUnknown.value || isNaN(Number(azimuth.value))) {
    return '0,0 0,0 0,0'; // Return invisible points
  }

  const length = 80;
  const arrowSize = 10;
  const angle = parseFloat(azimuth.value) - 90;
  const x = 100 + length * Math.cos((angle * Math.PI) / 180);
  const y = 100 + length * Math.sin((angle * Math.PI) / 180);

  const leftX = x - arrowSize * Math.cos(((angle + 150) * Math.PI) / 180);
  const leftY = y - arrowSize * Math.sin(((angle + 150) * Math.PI) / 180);
  const rightX = x - arrowSize * Math.cos(((angle - 150) * Math.PI) / 180);
  const rightY = y - arrowSize * Math.sin(((angle - 150) * Math.PI) / 180);

  return `${x},${y} ${leftX},${leftY} ${rightX},${rightY}`;
};

// Dialog methods
const handleDialogUpdate = (value: boolean) => {
  if (!value) {
    resetState();
  }
  emit('update:dialog', value);
};

const resetState = () => {
  distance.value = '0';
  azimuth.value = '0';
  marker.value = null;
  mapVisible.value = false;
  activePanel.value = 0;
  distanceError.value = false;
  azimuthError.value = false;
  isDistanceUnknown.value = false;
  isAzimuthUnknown.value = false;
};

const proceed = () => {
  const selectedData = {
    distance: isDistanceUnknown.value ? 'не можливо визначити' : parseInt(distance.value),
    azimuth: isAzimuthUnknown.value ? 'не можливо визначити' : azimuth.value.includes('-') ? azimuth.value : parseInt(azimuth.value),
    coordinates: marker.value || { lat: 0, lng: 0 },
  };

  console.log('Emitting location data:', selectedData);

  emit('location-selected', selectedData);
  // handleDialogUpdate(false);
};

const skip = () => {
  const skippedData = {
    distance: -1,
    azimuth: -1,
    coordinates: { lat: 0, lng: 0 },
  };
  emit('location-selected', skippedData);
};

// Watchers
watch(() => props.dialog, (newValue) => {
  if (!newValue) {
    resetState();
  }
});

watch(activePanel, async (newVal) => {
  if (newVal === 1) {
    mapVisible.value = true;
    await nextTick();
    setTimeout(() => {
      if (map.value) {
        map.value.invalidateSize();
      }
    }, 500);
  } else {
    mapVisible.value = false;
  }
});

watch([azimuthError, distanceError], (value) => {
  if (value[0]||value[1]) {
    proceedDisabledButton.value = true;
  } else {
    proceedDisabledButton.value = false;
  }
});

watch(distance, () => {
  distanceError.value = false;
});
</script>

<style scoped>
.map-container {
  height: 500px;
  width: 100%;
}

.distance-section {
  margin-bottom: 16px;
}

.input-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.azimuth-section {
  display: flex;
  align-items: center;
  margin-top: 16px;
  justify-content: center;
}

.azimuth-circle {
  width: 100%;
  max-width: 220px;
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid black;
  position: relative;
  margin-top: 16px;
}

.error-text {
  color: red;
  font-size: 12px;
  margin-top: 2px;
}

.distance-input,
.azimuth-input {
  width: 100%;
}

.header {
  background-color: rgb(6, 26, 40);
  color: white;
}

.header-text {
  font-size: 16px;
  font-weight: bold;
}

.helper-text {
  font-size: 12px;
  color: #757575;
  margin-bottom: 8px;
}

.checkbox-wrapper {
  margin-top: 4px;
  margin-bottom: 8px;
}

.input-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.distance-input,
.azimuth-input {
  width: 100%;
  opacity: 1;
}

.distance-input:disabled,
.azimuth-input:disabled {
  opacity: 0.7;
}
</style>
