<template>
    <v-container class="stats-container" fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card elevation="2">
            <v-card-title class="headline">Статистика</v-card-title>
            <v-card-text>
              <div class="actions">
                <v-btn @click="handleExport" color="primary" class="custom-btn">
                  Експортувати в Excel
                </v-btn>
              </div>
              <events-table/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script setup>
  import { onMounted } from 'vue';
  import EventsTable from '@/components/EventsTable.vue';
  import apiClient from '@/services/axios';

  const handleExport = () => {
    apiClient.handleExport();
  }
  
  </script>
  
  <style scoped>
  .stats-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
  }
  
  .actions {
    text-align: right;
    margin-bottom: 10px;
  }
  
  .loading-indicator {
    display: block;
    margin: 0 auto;
  }
  
  .elevation-1 {
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .v-card {
    padding: 20px;
    background-color: white;
    border-radius: 10px;
  }
  
  .v-card-title {
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .v-card-text {
    padding-top: 10px;
  }
  
  .v-btn.custom-btn {
    font-weight: bold;
    background-color: #061A28;
    color: white;
  }
  </style>
