import { createApp } from 'vue';
import type { App as VueApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createPinia } from 'pinia';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import './styles.scss';
import { VTreeview } from 'vuetify/labs/VTreeview';

const vuetify = createVuetify({
    components: {
        ...components,
        VTreeview,
    },
    directives,
});

const pinia = createPinia();
const app: VueApp = createApp(App);

app
    .use(router)
    .use(vuetify)
    .use(pinia)
    .mount('#app');