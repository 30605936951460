import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref } from "vue"

const _hoisted_1 = { class: "headline" }

import { ref, computed, watch, nextTick } from 'vue';
import type { EventType, Position, EventLocation } from '@/types/events';
import { useEventCreationStore } from '@/stores/eventCreation';
import eventConfig from '@/config/eventConfig';
import EventTypeSelector from '@/components/EventCreation/EventTypeSelector.vue';
import MapSelectionView from '@/components/MapSelectionView.vue';
import EventNotesForm from '@/components/EventCreation/EventNotesForm.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCreationDialog',
  props: {
    dialog: { type: Boolean },
    position: {}
  },
  emits: ["update:dialog", "event-success", "event-error", "close", "select"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;

const eventStore = useEventCreationStore();

const positionData = ref({ ...props.position });
const alert = ref({ show: false, message: '', type: 'info' });

const isSelectionStep = computed(() =>
  ['type', 'subtype', 'sub-subtype'].includes(eventStore.currentStep)
);

const dialogTitle = computed(() => {
  switch (eventStore.currentStep) {
    case 'type':
      return 'Оберіть тип';
    case 'subtype':
      return `Оберіть для ${eventStore.selectedType?.name}`;
    case 'sub-subtype':
      return `Оберіть для ${eventStore.selectedSubtype?.name}`; 
    case 'coordinates-square':
      return `Визначте ${getLastSelectedName()}`;
    case 'expenditure-balance':
      return `Визначте ${getLastSelectedName()}`;
    case 'text-area':
      return `Введіть ${getLastSelectedName()}`;
    case 'map':
      return `Визначте координати для ${getLastSelectedName()}`;
    case 'notes':
      return `Додатково для ${getLastSelectedName()}`;
    default:
      return 'Створення події';
  }
});

const currentItems = computed(() => {
  switch (eventStore.currentStep) {
    case 'type':
      return eventConfig.eventTypes;
    case 'subtype':
      return eventStore.selectedType?.children || [];
    case 'sub-subtype':
      return eventStore.selectedSubtype?.children ||
        (eventStore.selectedType?.children?.find(child =>
          child.id === eventStore.selectedSubtype?.id
        ))?.children || [];
    default:
      return [];
  }
});

function getLastSelectedName(): string {
  return eventStore.selectedSubSubtype ||
    eventStore.selectedSubtype?.name ||
    eventStore.selectedType?.name || '';
}

function handleSelection(selection: EventType | string[] | string) {
  if (Array.isArray(selection)) {
    eventStore.selectedSubSubtypes = selection;
    if (selection.length > 0) {
      eventStore.determineNextStep(eventStore.selectedSubtype!);
    }
  } else if (typeof selection === 'string') {
    eventStore.selectedSubSubtype = selection;
    eventStore.determineNextStep(eventStore.selectedSubtype!);
  } else {
    if (eventStore.currentStep === 'type') {
      eventStore.selectType(selection);
    } else {
      eventStore.selectSubtype(selection);
    }
  }
}

async function handleLocationSelected(location: EventLocation) {
  console.log('Received location data:', location);
  
  eventStore.$patch({ 
    location,
    currentStep: 'notes'
  });
}

async function handleSubmit() {
  const result = await eventStore.createEvent(positionData.value);

  console.log('positon', props.position);
  if (result.success) {
    emit('event-success', result.message);
    closeDialog();
  } else {
    showAlert(result.message, 'error');
  }
}

function showAlert(message: string, type: string = 'info') {
  alert.value = {
    show: true,
    message,
    type
  };
}

function handleDialogUpdate(value: boolean) {
  if (!value) {
    closeDialog();
  }
}

const handleMapDialogClose = (value) => {
  if (!value) {
    eventStore.reset();
    emit('update:dialog', false);
    emit('close');
  }
}

function closeDialog() {
  nextTick(() => {
    eventStore.reset();
    emit('update:dialog', false);
    emit('close');
  });
}

// Watch for dialog prop changes to ensure cleanup
watch(() => props.dialog, (newValue) => {
  if (!newValue) {
    closeDialog();
  }
});

watch(() => props.position, (newValue) => {
  positionData.value = { ...newValue };
});


return (_ctx: any,_cache: any) => {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialog,
    "onUpdate:modelValue": handleDialogUpdate,
    "max-width": "600"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_row, { justify: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, {
                    class: "custom-red-btn",
                    onClick: closeDialog,
                    color: "red-darken-4",
                    icon: "mdi-close"
                  })
                ]),
                _: 1
              }),
              _createElementVNode("span", _hoisted_1, _toDisplayString(dialogTitle.value), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (alert.value.show)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: alert.value.type,
                    dismissible: "",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (alert.value.show = false))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(alert.value.message), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              (isSelectionStep.value)
                ? (_openBlock(), _createBlock(EventTypeSelector, {
                    key: 1,
                    items: currentItems.value,
                    "selection-type": _unref(eventStore).currentStep,
                    "allow-multiple": Boolean(_unref(eventStore).selectedSubtype?.is_multichoice),
                    dialog: _ctx.dialog,
                    onSelect: handleSelection
                  }, null, 8, ["items", "selection-type", "allow-multiple", "dialog"]))
                : _createCommentVNode("", true),
              (_unref(eventStore).currentStep === 'map')
                ? (_openBlock(), _createBlock(MapSelectionView, {
                    key: 2,
                    dialog: _ctx.dialog,
                    items: currentItems.value,
                    onLocationSelected: handleLocationSelected,
                    "onUpdate:dialog": handleMapDialogClose
                  }, null, 8, ["dialog", "items"]))
                : (_unref(eventStore).currentStep === 'notes')
                  ? (_openBlock(), _createBlock(EventNotesForm, {
                      key: 3,
                      modelValue: _unref(eventStore).notes,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(eventStore).notes) = $event)),
                      "show-allies": Boolean(_unref(eventStore).selectedType?.allies),
                      "show-enemies": Boolean(_unref(eventStore).selectedType?.enemies)
                    }, null, 8, ["modelValue", "show-allies", "show-enemies"]))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          (_unref(eventStore).currentStep === 'notes')
            ? (_openBlock(), _createBlock(_component_v_card_actions, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_spacer),
                  _createVNode(_component_v_btn, {
                    disabled: _unref(eventStore).loading,
                    class: "custom-green-btn-x2",
                    onClick: handleSubmit
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" CТВОРИТИ ПОДІЮ ")
                    ])),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})