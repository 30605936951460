<template>
  <v-card variant="flat">
    <v-card-title>
      <span class="headline">Координати або квадрат</span>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Координати"
          v-model="coordinates"
          type="text"
          @keydown="onKeydown"
        />
      </v-responsive>
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Квадрат"
          v-model="square"
          type="text"
          @keydown="onKeydown"
        />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <v-btn class="custom-green-btn" @click="proceed" :disabled="disabled"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useEventCreationStore } from "@/stores/eventCreation";

const emit = defineEmits(["select"]);

const eventStore = useEventCreationStore();

const square = ref("");
const coordinates = ref("");
const disabled = computed(() => !coordinates.value && !square.value);

function onKeydown(e: KeyboardEvent) {
  e.stopPropagation();
}

function proceed() {
  if (coordinates.value) {
    eventStore.$patch({
      displayedDescription:
        (eventStore.displayedDescription += `Координати: ${coordinates.value}\n`),
    });
  }

  if (square.value) {
    eventStore.$patch({
      displayedDescription:
        (eventStore.displayedDescription += `Квадрат: ${square.value}\n`),
    });
  }

  emit("select");
}
</script>
