// stores/eventCreation.ts
import { defineStore } from 'pinia';
import type { EventType, EventLocation, EventNotes, EventData, EventStep, Position } from '@/types/events';
import { formatMGRS } from "@/services/utils";
import { forward } from "mgrs";
import apiClient from '@/services/axios';
import { getCurrentTime, formatDate } from '@/utils/timeUtils';
import { useAdminStore } from './admin';

function formatNotes(notes: EventNotes): string {
  const lines = [
    notes.allies ? `Кількість о/с на позиції: ${notes.allies.trim()}` : '',
    notes.enemies ? `Кількість о/с противника: ${notes.enemies.trim()}` : '',
    notes.details ? `Додатково: ${notes.details.trim()}` : '',
    notes.status ? `Статус: ${notes.status.trim()}` : ''
  ].filter(Boolean);

  if (lines.length === 0) return ''; // Якщо немає жодних даних, повертаємо порожній рядок

  if (lines.length === 1) {
    return lines[0] + '\n'; // Якщо один елемент, додаємо \n в кінці
  }

  if (lines.length === 2) {
    return lines.join('\n') + '\n'; // Якщо два елементи, додаємо \n між ними і в кінці
  }

  // Якщо три чи більше елементів, додаємо \n між усіма елементами
  return lines.join('\n') + '\n'; 
}

function normalizeSpaces(str: string): string {
  return str.replace(/\s+/g, ' ').trim();
}

function removeExtraSymbols(str: string | null | undefined): string {
  if (!str) return '';
  return str.replace(/\n/g, '')
}

function displayedDescriptionHelper(item: EventType): string {

  if(item.displayText !== null){
    return item.displayText || item.name
  }

    return ''
  
}

// Store interface
interface EventState {
  currentStep: EventStep;
  selectedType: EventType | null;
  selectedSubtype: EventType | null;
  selectedSubSubtype: string | null;
  selectedSubSubtypes: string[];
  selectedId: number;
  description: string;
  displayedDescription: string;
  location: EventLocation | null;
  notes: EventNotes;
  loading: boolean;
  position: Position | null;
  solution?: string;
}

export const useEventCreationStore = defineStore('eventCreation', {
  state: (): EventState => ({
    currentStep: 'type',
    selectedType: null,
    selectedSubtype: null,
    selectedSubSubtype: null,
    selectedSubSubtypes: [],
    selectedId: -1,
    description: '',
    displayedDescription: '',
    location: null,
    notes: {
      allies: '',
      enemies: '',
      details: '',
      status: '',
      time: ''
    },
    loading: false,
    position: null
  }),

  getters: {
    formattedChatData: (state) => {
      if (!state.selectedType || !state.position) return '';
    
      const adminStore = useAdminStore();
      const user = adminStore.currentUser!;
      const squadron = adminStore.squadrons.find(s => s.id === user.squadronId);
      const battalion = adminStore.battalions.find(b => b.id === squadron?.battalionId);

      const description = state.position.description ? ` (${state.position.description})` : '';
      let chatData = `Доповідь від ${state.position.name}${description}\n`;
          chatData += `Подія: ${state.displayedDescription}`;
    
      if (state.location) {
        // Handle azimuth
        const azimuth = state.location.azimuth;
        if (typeof azimuth === 'string' && azimuth === 'не можливо визначити') {
          chatData += `Азимут: не можливо визначити\n`;
        } else if (typeof azimuth === 'number' && azimuth !== -1 && azimuth !== 0) {
          chatData += `Азимут: ${azimuth}\n`;
        } else if (typeof azimuth === 'string' && azimuth.includes('-')) {
          chatData += `Азимут: ${azimuth}\n`;
        }
    
        // Handle distance
        const distance = state.location.distance;
        if (typeof distance === 'string' && distance === 'не можливо визначити') {
          chatData += `Дистанція: не можливо визначити\n`;
        } else if (typeof distance === 'number' && distance !== -1 && distance !== 0 && !isNaN(distance)) {
          chatData += `Дистанція: ${distance} м\n`;
        }
    
        if (state.location.coordinates.lat !== 0 || state.location.coordinates.lng !== 0) {
          const mgrs = forward([state.location.coordinates.lat, state.location.coordinates.lng]);
          chatData += `Координати: ${formatMGRS(mgrs)}\n`;
        }
      }
    
      const formattedNotes = formatNotes(state.notes);
      if (formattedNotes) {
        chatData += `${formattedNotes}`;
      }

      const time = state.notes.time ? state.notes.time : getCurrentTime();
    
      chatData += `\n\nЧас: ${time}\n`;
      chatData += `Дата: ${formatDate()}\n`;
      chatData += `\nЧерговий: ${user.callsign}${squadron ? `, ${squadron.name}` : ''}`;
      chatData += battalion ? ` (${battalion.name})` : '';
    
      return chatData.replace(/\n{2,}/g, '\n\n');
    }
  },

  actions: {
    selectType(item: EventType) {
      this.selectedType = item;
      this.selectedId = item.id ?? -1;
      this.description = item.name;
      this.displayedDescription = displayedDescriptionHelper(item)
      this.determineNextStep(item);
    },
    selectSubtype(item: EventType) {
      const displayedDescription = displayedDescriptionHelper(item);

      this.selectedSubtype = item;
      this.selectedId = item.id ?? this.selectedId;
      this.description += item.name;
      this.displayedDescription += item.disableLeftSpaces ? displayedDescription: ` ${displayedDescription}`;
      this.determineNextStep(item);
    },

    determineNextStep(item: EventType) {
      const childrenLength = item.children?.length ?? 0;
      
      if (childrenLength > 0) {
        if (this.currentStep === 'subtype') {
          this.currentStep = 'sub-subtype';
        }
        else if (this.currentStep === 'type') {
          this.currentStep = 'subtype';
        }
        else if (this.currentStep === 'sub-subtype') {
          this.currentStep = 'sub-subtype';
        }
      } else if (item.skipMapStep) {
        this.currentStep = 'notes';
        this.location = {
          azimuth: -1,
          distance: -1,
          coordinates: { lat: 0, lng: 0 }
        };
      } else {
        this.currentStep = 'map';
      }
    },

    async createEvent(position: Position): Promise<{ success: boolean; message: string }> {
      this.loading = true;
      this.position = position; // Set position for chat data formatting

      try {
        const eventData = this.prepareEventData(position);
        console.log('New event', eventData);
        await apiClient.createEvent(eventData);

        if (!position.isCustomPosition) {
          await this.updatePositionStatus(position);
        }

        return { success: true, message: 'Подію успішно створено' };
      } catch (error) {
        console.error('Error creating event:', error);
        return { success: false, message: 'Помилка при створенні події' };
      } finally {
        this.loading = false;
      }
    },

    async updatePositionStatus(position: Position) {
      if (this.selectedType?.isUnderAttack !== undefined) {
        position.isUnderAttack = this.selectedType.isUnderAttack;
      }
      if (this.selectedType?.isUnderFire !== undefined) {
        position.isUnderFire = this.selectedType.isUnderFire;
      }

      await apiClient.editPosition(position.id, {
        isUnderAttack: position.isUnderAttack,
        isUnderFire: position.isUnderFire,
      });
    },

    prepareEventData(position: Position): EventData {
      const distance = typeof this.location?.distance === 'string' ? 
        this.location.distance === 'не можливо визначити' ? -1 : parseInt(this.location.distance) : 
        this.location?.distance || -1;
    
      const azimuth = typeof this.location?.azimuth === 'string' ? 
        this.location.azimuth === 'не можливо визначити' ?
         -1 : this.location.azimuth.includes('-') ?
          this.location.azimuth : parseInt(this.location.azimuth) : 
        this.location?.azimuth || -1;

      const time = this.notes.time ? this.notes.time : getCurrentTime();
      const coordinatesExists = this.location?.coordinates.lat !== 0 || this.location?.coordinates.lng !== 0;

      return {
        type: removeExtraSymbols(this.selectedType?.name) || '',
        description: removeExtraSymbols(normalizeSpaces(this.displayedDescription)),
        locationLat: this.location?.coordinates.lat || null,
        locationLong: this.location?.coordinates.lng || null,
        notes: formatNotes(this.notes),
        time: `${formatDate()} ${time}:00.000`,
        positionId: position.id,
        distance,
        azimuth,
        mgrs: this.location && coordinatesExists ? forward([this.location.coordinates.lng, this.location.coordinates.lat]) : 'N/A',
        positionName: position.positionName?.trim() || position.name,
        isCustomPosition: position.isCustomPosition || false,
        selectedId: this.selectedId,
        chatData: this.formattedChatData
      };
    },

    reset() {
      this.$reset();
      this.notes.time = '';
    }
  }
});
