import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "chat-section" }
const _hoisted_2 = {
  key: 0,
  class: "chat-chips"
}
const _hoisted_3 = { class: "chat-section" }
const _hoisted_4 = {
  key: 0,
  class: "chat-chips"
}

import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';

interface Props {
    dialogVisible: boolean;
    isEditing: boolean;
    form: FormState['battalion'];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateEditBattalionDialog',
  props: {
    dialogVisible: { type: Boolean },
    isEditing: { type: Boolean },
    form: {}
  },
  emits: ["update:dialogVisible", "save", "close"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const formRef = ref<any>(null);
const formValid = ref(false);

const localForm = reactive({
    id: null as string | null,
    name: '',
    signalChatsData: [] as { name: string; config: any }[],
    whatsappChatsData: [] as { name: string; config: any }[]
});

watch(
    () => props.form,
    (newForm) => {
        localForm.id = newForm.id;
        localForm.name = newForm.name;
        localForm.signalChatsData = [...newForm.signalChatsData]
        localForm.whatsappChatsData = [...newForm.whatsappChatsData]
    },
    { immediate: true, deep: true }
);

const alert = ref({
    show: false,
    type: 'error' as const,
    message: ''
});

const newSignalChat = ref('');
const newWhatsappChat = ref('');

function showAlert(message: string, type: 'error' | 'success' = 'error') {
    alert.value = {
        show: true,
        type,
        message
    };

    setTimeout(() => {
        alert.value.show = false;
    }, 3000);
}

function handleAddSignalChat() {
    const trimmedChat = newSignalChat.value.trim();
    if (trimmedChat && !localForm.signalChatsData.some(chat => chat.name === trimmedChat)) {
        localForm.signalChatsData.push({ name: trimmedChat, config: null });
        newSignalChat.value = '';
        console.log('Added to signalChatsData:', localForm.signalChatsData); // Debug log for added chat
    }
}

function handleAddWhatsappChat() {
    const trimmedChat = newWhatsappChat.value.trim();
    if (trimmedChat && !localForm.whatsappChatsData.some(chat => chat.name === trimmedChat)) {
        localForm.whatsappChatsData.push({ name: trimmedChat, config: null });
        newWhatsappChat.value = '';
        console.log('Added to whatsappChatsData:', localForm.whatsappChatsData); // Debug log for added chat
    }
}

function handleRemoveChat(type: 'signalChatsData' | 'whatsappChatsData', chat: { name: string; config: any }) {
    localForm[type] = localForm[type].filter(c => c.name !== chat.name);
    console.log(`Removed from ${type}:`, localForm[type]); // Debug log for removed chat
}

async function handleSave() {
    const { valid } = await formRef.value?.validate();

    if (!valid) {
        showAlert('Будь ласка, заповніть всі обовязкові поля');
        return;
    }

    // Convert reactive proxy to plain object
    const plainLocalForm = JSON.parse(JSON.stringify(localForm));
    console.log('Form data before save (plain object):', plainLocalForm);

    emit('save', props.isEditing, plainLocalForm);
}

function handleClose() {
    emit('update:dialogVisible', false);
    emit('close');
    formRef.value?.resetValidation();
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:dialogVisible', $event))),
    "max-width": "500px",
    "onClick:outside": handleClose
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.isEditing ? 'Редагувати Підрозділ' : 'Створити Підрозділ'), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (alert.value.show)
                ? (_openBlock(), _createBlock(_component_v_alert, {
                    key: 0,
                    type: alert.value.type,
                    dismissible: "",
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (alert.value.show = false)),
                    transition: "scale-transition"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(alert.value.message), 1)
                    ]),
                    _: 1
                  }, 8, ["type"]))
                : _createCommentVNode("", true),
              _createVNode(_component_v_form, {
                ref_key: "formRef",
                ref: formRef,
                modelValue: formValid.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((formValid).value = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    modelValue: localForm.name,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((localForm.name) = $event)),
                    label: "Назва",
                    rules: [v => !!v || 'Назва обовязкова'],
                    required: ""
                  }, null, 8, ["modelValue", "rules"]),
                  _createElementVNode("div", _hoisted_1, [
                    _cache[6] || (_cache[6] = _createElementVNode("h3", null, "Signal чати", -1)),
                    _createVNode(_component_v_text_field, {
                      modelValue: newSignalChat.value,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((newSignalChat).value = $event)),
                      label: "Додати Signal чат",
                      onKeyup: _withKeys(handleAddSignalChat, ["enter"]),
                      onBlur: handleAddSignalChat
                    }, null, 8, ["modelValue"]),
                    (localForm.signalChatsData.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localForm.signalChatsData, (chat) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: `signal-${chat.name}`,
                              closable: "",
                              "onClick:close": ($event: any) => (handleRemoveChat('signalChatsData', chat)),
                              class: "ma-1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(chat.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick:close"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_3, [
                    _cache[7] || (_cache[7] = _createElementVNode("h3", null, "WhatsApp чати", -1)),
                    _createVNode(_component_v_text_field, {
                      modelValue: newWhatsappChat.value,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((newWhatsappChat).value = $event)),
                      label: "Додати WhatsApp чат",
                      onKeyup: _withKeys(handleAddWhatsappChat, ["enter"]),
                      onBlur: handleAddWhatsappChat
                    }, null, 8, ["modelValue"]),
                    (localForm.whatsappChatsData.length)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(localForm.whatsappChatsData, (chat) => {
                            return (_openBlock(), _createBlock(_component_v_chip, {
                              key: `whatsapp-${chat.name}`,
                              closable: "",
                              "onClick:close": ($event: any) => (handleRemoveChat('whatsappChatsData', chat)),
                              class: "ma-1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(chat.name), 1)
                              ]),
                              _: 2
                            }, 1032, ["onClick:close"]))
                          }), 128))
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "custom-red-btn",
                onClick: handleClose
              }, {
                default: _withCtx(() => _cache[8] || (_cache[8] = [
                  _createTextVNode(" Відмінити ")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn",
                onClick: handleSave,
                disabled: !formValid.value
              }, {
                default: _withCtx(() => _cache[9] || (_cache[9] = [
                  _createTextVNode(" Зберегти ")
                ])),
                _: 1
              }, 8, ["disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})