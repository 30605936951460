import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, onMounted, watch } from 'vue';
import fullChatsConfig from '@/config/eventConfig';
interface TreeItem {
  id: string | number;
  name: string;
  children?: TreeItem[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChatConfigDialog',
  props: {
    dialogVisible: { type: Boolean },
    chatName: {},
    chatType: {},
    config: {}
  },
  emits: ["update:dialog-visible", "save"],
  setup(__props: any, { emit: __emit }) {

const chatsConfig = {
  //removing extra fields to optimize rendering
  // this very bad approach, need to be optimized
  eventTypes: fullChatsConfig.eventTypes.map((evt) => {
    if (['Виконано вогневе завдання', 'Виконано завдання (БПЛА)', 'Бойова готовність'].includes(evt.name)) {
      return {
        id: evt.id,
        name: evt.name,
      }
    } else return evt;
  })
}

const props = __props;

const emit = __emit;

const selection = ref<TreeItem[]>([]);
const items = ref<TreeItem[]>([]);
const loading = ref(true);
const selectionKey = ref(0);

const loadAndPrepareData = () => {
  // Ensure chatsConfig.eventTypes exists and is an array
  items.value = Array.isArray(chatsConfig.eventTypes) ? chatsConfig.eventTypes : [];

  if (props.config && Array.isArray(props.config)) {
    preselectNodes(props.config);
  } else {
    selectAllNodes(items.value);
  }
};

const onSelectionUpdate = (newSelection: TreeItem[]) => {
  if (Array.isArray(newSelection)) {
    selection.value = newSelection;
  }
};

const handleSave = () => {
  const selectedNodes = selection.value.map(node => ({
    id: node.id,
    name: node.name
  }));

  emit('save', selectedNodes);
};

const handleClose = () => {
    emit('update:dialog-visible', false);
};

const preselectNodes = (config: any[]) => {
  if (!Array.isArray(config)) return;

  const validConfig = config.filter(item => item && 
    typeof item === 'object' && 
    'id' in item && 
    item.id !== undefined && 
    item.id !== null
  );

  const selectedIds = validConfig.map(item => item.id);
  
  const selectNodes = (nodes: TreeItem[]) => {
    if (!Array.isArray(nodes)) return;

    nodes.forEach(node => {
      if (selectedIds.includes(node.id)) {
        if (!selection.value.find(selectedNode => selectedNode.id === node.id)) {
          selection.value.push(node);
        }
      }
      if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        selectNodes(node.children);
      }
    });
  };

  selectNodes(items.value);
  selection.value = [...selection.value];
  selectionKey.value++;
  
  // Using nextTick-like behavior
  setTimeout(() => {
    items.value = [...items.value];
  });
};

const selectAllNodes = (nodes: TreeItem[]) => {
  if (!Array.isArray(nodes)) return;

  const selectAll = (nodesToSelect: TreeItem[]) => {
    nodesToSelect.forEach(node => {
      if (!selection.value.find(selectedNode => selectedNode.id === node.id)) {
        selection.value.push(node);
      }
      if (node.children && Array.isArray(node.children) && node.children.length > 0) {
        selectAll(node.children);
      }
    });
  };

  selectAll(nodes);
  selectionKey.value++;
  
  setTimeout(() => {
    items.value = [...items.value];
  });
};

// Watch for changes in config prop
watch(() => props.config, (newConfig) => {
  selection.value = [];
  loadAndPrepareData();
}, { deep: true });

onMounted(() => {
  loadAndPrepareData();
  loading.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_toolbar = _resolveComponent("v-toolbar")!
  const _component_v_progress_circular = _resolveComponent("v-progress-circular")!
  const _component_v_treeview = _resolveComponent("v-treeview")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:dialog-visible', $event))),
    "max-width": "600px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_toolbar, { flat: "" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_toolbar_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Події для чату " + _toDisplayString(_ctx.chatName), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              (loading.value)
                ? (_openBlock(), _createBlock(_component_v_progress_circular, {
                    key: 0,
                    indeterminate: "",
                    color: "primary",
                    class: "ma-5"
                  }))
                : (_openBlock(), _createBlock(_component_v_treeview, {
                    key: selectionKey.value,
                    selected: selection.value,
                    "onUpdate:selected": [
                      _cache[0] || (_cache[0] = ($event: any) => ((selection).value = $event)),
                      onSelectionUpdate
                    ],
                    items: items.value,
                    "select-strategy": "classic",
                    "item-title": "name",
                    "item-value": "id",
                    "return-object": "",
                    selectable: "",
                    dense: ""
                  }, null, 8, ["selected", "items"]))
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "custom-red-btn",
                onClick: handleClose
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Відмінити")
                ])),
                _: 1
              }),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn",
                onClick: handleSave
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Зберегти")
                ])),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model-value"]))
}
}

})