import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, computed } from "vue";
import { useEventCreationStore } from "@/stores/eventCreation";


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCoordinatesSquareFrom',
  emits: ["select"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const eventStore = useEventCreationStore();

const square = ref("");
const coordinates = ref("");
const disabled = computed(() => !coordinates.value && !square.value);

function onKeydown(e: KeyboardEvent) {
  e.stopPropagation();
}

function proceed() {
  if (coordinates.value) {
    eventStore.$patch({
      displayedDescription:
        (eventStore.displayedDescription += `Координати: ${coordinates.value}\n`),
    });
  }

  if (square.value) {
    eventStore.$patch({
      displayedDescription:
        (eventStore.displayedDescription += `Квадрат: ${square.value}\n`),
    });
  }

  emit("select");
}

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, null, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("span", { class: "headline" }, "Координати або квадрат", -1)
        ])),
        _: 1
      }),
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Координати",
                modelValue: coordinates.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((coordinates).value = $event)),
                type: "text",
                onKeydown: onKeydown
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Квадрат",
                modelValue: square.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((square).value = $event)),
                type: "text",
                onKeydown: onKeydown
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_btn, {
            class: "custom-green-btn",
            onClick: proceed,
            disabled: disabled.value
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})