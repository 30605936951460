<template>
  <v-card variant="flat">
    <v-card-title>
      <span class="headline">Витрати та залишок</span>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Витрати"
          v-model="expenditure"
          type="text"
          @keydown="onKeydown"
        />
      </v-responsive>
      <v-responsive class="mx-auto" width="100%">
        <v-text-field
          label="Залишок"
          v-model="balance"
          type="text"
          @keydown="onKeydown"
        />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <v-btn class="custom-green-btn" @click="proceed" :disabled="disabled"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useEventCreationStore } from "@/stores/eventCreation";

const eventStore = useEventCreationStore();

const emit = defineEmits(["select"]);

const expenditure = ref("");
const balance = ref("");
const disabled = computed(() => !expenditure.value || !balance.value);

function onKeydown(e: KeyboardEvent) {
  e.stopPropagation();
}

function proceed() {
  eventStore.$patch({
    displayedDescription:
      (eventStore.displayedDescription += `Витрати: ${expenditure.value}\n`),
  });

  eventStore.$patch({
    displayedDescription:
      (eventStore.displayedDescription += `Залишок: ${balance.value}\n`),
  });

  emit("select");
}
</script>
