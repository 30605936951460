import { defineStore } from 'pinia';
import apiClient from '@/services/axios';
import { formatMGRS } from "@/services/utils";

export const useEventsStore = defineStore('events', {
  state: () => ({
    events: [],
    eventsCount: 0,
    positions: {},
    loading: true,
  }),

  getters: {
    formattedEvents: (state) => {
      return state.events
        .slice()
        .map(event => {
          const positionName = event.positionId
            ? state.positions[event.positionId] || 'Невідома позиція'
            : event.positionName || 'Невідома позиція';

          // delete 31NAA6602100000 with migration from exising events
          const formattedMGRS = ['31NAA6602100000', 'N/A'].includes(event.mgrs) ?  '' : formatMGRS(event.mgrs);

          return {
            ...event,
            position: positionName,
            reporter: {
              callsign: event.reporter?.callsign || 'Невідомий',
            },
            mgrs: formattedMGRS,
          };
        });
    }
  },

  actions: {
    async fetchPositions() {
      try {
        const { data } = await apiClient.getPositions();
        this.positions = data.reduce((map, position) => {
          map[position.id] = position.name;
          return map;
        }, {});
      } catch (error) {
        console.error('Error fetching positions:', error);
      }
    },

    async fetchEvents(page, limit) {
      this.loading = true;
      try {
        await this.fetchPositions();
        const { data: { data, count } } = await apiClient.getEvents(page, limit);
        this.events = data;
        this.eventsCount = count;
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
