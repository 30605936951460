<template>
    <v-app>
      <!-- App Bar -->
      <v-app-bar app elevation="4">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-toolbar-title>A2 <span class="small-text">v.{{version}}</span></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="handleLogout" color="primary" class="custom-btn">
          Вихід
        </v-btn>
      </v-app-bar>
  
      <!-- Navigation Drawer -->
      <v-navigation-drawer
        v-model="drawer"
        app
        permanent
      >
        <v-list dense>
          <v-list-item
            v-for="tab in availableTabs"
            :key="tab.name"
            :to="{ name: tab.name }"
            @click="drawer = false"
            :value="tab.name"
          >
            <template v-slot:prepend>
              <v-icon>{{ tab.icon }}</v-icon>
            </template>
            <v-list-item-title>{{ tab.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
  
      <!-- Main Content -->
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </template>
  
  <script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useStore } from "@/services/store";
  import version from '@/version';
  
  // State
  const drawer = ref(false);
  const user = ref(JSON.parse(localStorage.getItem('user')));
  const router = useRouter();
  const route = useRoute();
  const store = useStore();
  
  // Navigation tabs configuration
  const tabs = [
    { 
      name: 'Event', 
      title: 'Події', 
      icon: 'mdi-calendar',
      access: (role) => role !== 'stat'
    },
    { 
      name: 'Stats', 
      title: 'Статистика', 
      icon: 'mdi-chart-bar',
      access: (role) => role === 'stat' || role.includes('admin')
    },
    { 
      name: 'Admin', 
      title: 'Адміністрування', 
      icon: 'mdi-account-cog',
      access: (role) => role.includes('admin')
    }
  ];
  
  // Computed
  const availableTabs = computed(() => 
    tabs.filter(tab => tab.access(user.value?.role))
  );
  
  const defaultRoute = computed(() => {
    if (!user.value) return 'Login';
    
    if (user.value.role === 'stat') return 'Stats';
    return 'Event';
  });
  
  // Methods
  async function handleLogout() {
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    store.clearAll();
    await router.push({ name: 'Login' });
  }
  
  // Lifecycle
  onMounted(() => {
    console.log('DashboardView Mounted');
    if (route.name !== defaultRoute.value) {
      router.replace({ name: defaultRoute.value });
    }
  });
  </script>
  
  <style scoped>
  .v-list-item--active {
    background-color: darkgray;
    color: white;
  }
  
  .v-list-item--active .v-icon {
    color: white;
  }
  
  .custom-btn {
    margin-right: 16px;
  }
  .small-text {
    font-size: 13px;
    font-weight: normal;
  }
  </style>