<template>
  <v-container class="login-container" fluid>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-card elevation="10">
          <v-card-title class="text-h5">Вхід A2</v-card-title>
          <v-card-text>
            <login-form 
              :loading="loading"
              :error-message="errorMessage"
              v-model:showAlert="showAlert"
              @submit="handleLogin"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import LoginForm from '@/components/LoginForm.vue';

const loading = ref(false);
const showAlert = ref(false);
const errorMessage = ref('');

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

async function handleLogin({ username, password }) {
  loading.value = true;
  showAlert.value = false;

  try {
    await authStore.login(username, password);
    router.push({ path: '/dashboard' });
  } catch (error) {
    console.error('Error during login:', error);
    errorMessage.value = 'Невірне імʼя або пароль!';
    showAlert.value = true;
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  if (route.query.sessionExpired) {
    errorMessage.value = 'Сесія закінчилася, будь ласка, увійдіть знову.';
    showAlert.value = true;
  }
});
</script>

<style scoped>
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
</style>